import CustomApiError from './CustomError';

export default class LoginError extends CustomApiError {
  toDictionary() {
    return {
      REPROVED_ACCOUNT:
        'Analisamos seu cadastro e infelizmente ele não foi aprovado. No futuro, se você ainda quiser vender conosco, esperamos que volte e tente novamente.',
      BLACKLISTED_ACCOUNT:
        'Sua conta foi bloqueada. Verifique seu e-mail para mais esclarecimentos.',
    };
  }
}
