import swal from 'sweetalert2';

class AlertPlugin {
  onCancel = () => { }
  onClose = () => { }
  onConfirm = () => { }

  error(title, message) {
    swal({
      title: title,
      text: message,
      type: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
    })
      .then((result) => {
        this.onClose(result);
      });
  }

  info(title, message) {
    swal({
      title: title,
      text: message,
      type: 'success',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
    })
      .then((result) => {
        this.onClose(result);
      });
  }

  question(title, question) {
    swal({
      title: title,
      text: question,
      type: 'warning',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      focusConfirm: false,
      focusCancel: true,
      allowOutsideClick: false,
      reverseButtons: true,
    })
      .then((result) => {
        if (result.dismiss) {
          this.onCancel(result);
        } else {
          this.onConfirm(result);
        }

        this.onClose(result);
      });
  }
}

const alert = new AlertPlugin();

export default function install(Vue) {
  Object.defineProperties(Vue.prototype, {
    $alert: {
      get() {
        return alert;
      },
    },
  });
}
