import { ACCESS, EXIT } from './mutations-type.js';

const moduleCreditProtection = {
  namespaced: true,
  state: {
    isQueryScreenSpcSerasa: false,
  },
  actions: {
    SetQueryScreenSpcSerasa(context) {
      context.commit(ACCESS);
    },
    ExitQueryScreenSpcSerasa(context) {
      context.commit(EXIT);
    },
  },
  mutations: {
    [ACCESS](state) {
      state.isQueryScreenSpcSerasa = true;
    },
    [EXIT](state) {
      state.isQueryScreenSpcSerasa = false;
    },
  },
  getters: {
    creditProtectionIsLeavingTheScreen(state) {
      return state.isQueryScreenSpcSerasa;
    },
  },
};

export default moduleCreditProtection;
