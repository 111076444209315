import CustomApiError from './CustomError';

export default class CreateNewPasswordError extends CustomApiError {
  toDictionary() {
    return {
      EXPIRED_HASH: 'Link expirado.',
      INVALID_HASH: 'Link inválido.',
      WRONG_PASSWORD: 'Você digitou a senha atual incorreta.',
      PASSWORD_USED_RECENTLY:
        'Não foi possível alterar pois você utilizou esta senha recentemente.',
      AUTH_CODE_INVALID: 'Código de autorização inválido.',
    };
  }
}
