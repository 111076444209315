<template>
  <StyledDiv
    :primaryColor="theme ? theme.primaryColor : getlayout.primaryColor"
    :secondaryColor="theme ? theme.secondaryColor : getlayout.secondaryColor"
    :tertiaryColor="theme ? theme.tertiaryColor : getlayout.tertiaryColor"
  >
    <slot />
  </StyledDiv>
</template>
<script>
import styled from 'vue-styled-components';
import { mapGetters, mapState } from 'vuex';

const divProps = {
  primaryColor: String,
  secondaryColor: String,
  tertiaryColor: String,
};

const StyledDiv = styled('div', divProps)`
  > h4,
  > h2,
  > a,
  label {
    color: ${(props) => props.tertiaryColor} !important;
  }

  > .details-balance,
  > .btn-pill,
  > .btn-orange,
  > .btn-confirm,
  > .btn-transference,
  input[type='checkbox']:checked + label.btn-transference,
  input[type='checkbox']:checked + label,
  input[type='checkbox']:checked + label:active {
    background: linear-gradient(
      147.43deg,
      ${(props) => props.primaryColor} 20.2%,
      ${(props) => props.secondaryColor} 100%
    ) !important;
    color: #ffffff !important;
    border: none;
    box-shadow: none;
    outline: none;
  }

  .btn:not(:disabled):not(.disabled):active {
    background: linear-gradient(
      147.43deg,
      ${(props) => props.primaryColor} 20.2%,
      ${(props) => props.secondaryColor} 100%
    ) !important;
    color: #ffffff !important;
  }

  .btn-pill i {
    color: #ffffff !important;
  }

  .btn-group .btn {
    background: transparent !important;
    border-radius: 0px !important;
    border: none;
    outline: none !important;
    box-shadow: none;
    transition: background-color 0.5s ease;
  }

  .btn-group .active {
    color: ${(props) => props.primaryColor}
    border-bottom: 1px solid;
    border-width: thin;
    transition: border 0.5s ease;
  }
  
  .btn-group .active:hover {
    opacity: 0.8;
  }

  .btn-group .btn:active:focus {
    background: transparent !important;
    color: ${(props) => props.primaryColor} !important;
  }

  .btn-group .btn:not(.active):hover {
    color: ${(props) => props.primaryColor}
  }

  > .btn-outline,
  > .btn-outline-orange {
    color: ${(props) => props.tertiaryColor} !important;
    border-color: ${(props) => props.tertiaryColor} !important;
    background: none !important;
  }

  .btn-link-orange {
    color: ${(props) => props.tertiaryColor} !important;
    background: none !important;
  }

  .btn-pill.btn-outline-orange {
    color: ${(props) => props.tertiaryColor} !important;
    border: solid thin ${(props) => props.tertiaryColor} !important;
    background: none !important;
    box-shadow: none !important;
  }

  > div {
    backgrond: ${(props) => props.primaryColor} !important;
  }

  .apexcharts-series path {
    fill: ${(props) => props.tertiaryColor} !important;
  }

  .line-chart .apexcharts-series path {
    fill: none !important;
    stroke: 0 !important;
  }

  *[seriesName='Bandeira'] path {
    stroke: none !important;
  }

  label.radio-selector input:checked + span.yellow-card-radio,
  label.radio-selector input + span.yellow-card-radio:hover,
  .dropdown-item:hover,
  .btn-applay {
    background: ${(props) => props.tertiaryColor} !important;
    box-shadow: inset 0px -8px 22px -14px rgba(0, 0, 0, 0.1);
  }

  .breadcrumb .btn-group .btn:hover,
  .btn-notification-open:hover,
  > .dropdown .btn:hover {
    color: ${(props) => props.tertiaryColor} !important;
  }

  .text-contrast,
  .text-orange,
  .balance-account .right .btn-gray .icon-plus {
    color: ${(props) => props.tertiaryColor} !important;
  }

  .nav-link.active,
  .card-radio:hover {
    background: ${(props) => props.tertiaryColor} !important;
    color: #ffffff !important;
  }

  .nav-link {
    background: #ffffff !important;
    color: ${(props) => props.tertiaryColor} !important;
  }

  .checkbox-badge {
    box-shadow: 0px 0px 0px 3px ${(props) => props.tertiaryColor};
  }

  .checkbox-badge[ditabled='disabled'] {
    box-shadow: 0px 0px 0px 3px #ced4da;
    background: ${(props) => props.tertiaryColor};
  }

  input:checked + div.checkbox-badge,
  .switch input.primary:checked + .slider {
    background: ${(props) => props.tertiaryColor} !important;
  }

  .icon,
  .icon-plus,
  .icon-minus,
  .icon-cloud-upload {
    color: ${(props) => props.tertiaryColor} !important;
    border: none;
  }

  .input-pill:hover,
  .input-pill:focus {
    border-color: ${(props) => props.tertiaryColor} !important;
  }

  .login-form {
    background: linear-gradient(
      147.43deg,
      ${(props) => props.primaryColor} 20.2%,
      ${(props) => props.secondaryColor} 100%
    );
  }

  .login-form button[type='submit'] {
    background: ${(props) => props.tertiaryColor};
    box-shadow: inset 0px -8px 22px -14px rgba(0, 0, 0, 0.1);
    border: none;
  }

  .input-group-addon.login,
  .footer {
    background: ${(props) => props.tertiaryColor} !important;
    box-shadow: inset 0px -8px 22px -14px rgba(0, 0, 0, 0.1);
  }

  .form-check-label.dropdown-item:hover {
    color: #ffffff !important;
  }

  .c-weeks .c-day-background,
  .avatar {
    background-color: ${(props) => props.tertiaryColor} !important;
  }

  .selector .selector__label,
  .selector .selector__options li:hover {
    color: ${(props) => props.tertiaryColor};
  }

  .login-img {
    max-height: 150px !important;
  }

  .dropdown-menu li:hover a {
    color: #fff;
  }

  .marker {
    background-color: ${(props) => props.tertiaryColor};
    color: #fff !important;
  }

  .page-link.active {
    background-color: ${(props) => props.tertiaryColor} !important;
    border-color: ${(props) => props.tertiaryColor} !important;
    color: #fff !important;
    outline: none !important;
  }

  .page-link {
    color: ${(props) => props.tertiaryColor} !important;
    outline: none !important;
  }
`;

export default {
  name: 'WhiteLabel',
  data() {
    return {
      layout: {
        primaryColor: window.localStorage.getItem('primaryColor'),
        secondaryColor: window.localStorage.getItem('secondaryColor'),
        tertiaryColor: window.localStorage.getItem('tertiaryColor'),
      },
    };
  },
  components: {
    StyledDiv,
  },
  computed: {
    getlayout() {
      return {
        primaryColor:
          this.layout.primaryColor != 'null' && this.layout.primaryColor != null
            ? this.layout.primaryColor
            : '#ff7606',
        secondaryColor:
          this.layout.secondaryColor != 'null' &&
          this.layout.secondaryColor != null
            ? this.layout.secondaryColor
            : '#fa3645',
        tertiaryColor:
          this.layout.tertiaryColor != 'null' &&
          this.layout.tertiaryColor != null
            ? this.layout.tertiaryColor
            : '#ff7606',
      };
    },
  },
  props: {
    theme: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
};
</script>
