
const telephone = function(phone) {
  if (phone.length === 10) {
    return phone
      .replace(/[^0-9]/g, '')
      .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  } else if (phone.length === 11) {
    return phone
      .replace(/[^0-9]/g, '')
      .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else if (phone.length === 12) {
    return phone
      .replace(/[^0-9]/g, '')
      .replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4 ');
  } else if (phone.length === 13) {
    return phone
      .replace(/[^0-9]/g, '')
      .replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4 ');
  }
};

export { telephone };
