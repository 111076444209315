const Layout = () => import('@/components/theme/Layout.vue');
const NotFound = () => import('@/components/theme/NotFound.vue');
const Welcome = () => import('@/components/theme/Welcome.vue');
const Dashboard = () => import('@/components/dashboard/Dashboard');
const Charts = () => import('@/components/dashboard/Charts');

// Comercial
const PhysicalCustomerDetails = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/customers/customer-details/PhysicalCustomer'
  );
const CorporateCustomerDetails = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/customers/customer-details/CorporateCustomer'
  );
const CustomerRegistration = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/customers/CustomerRegistration'
  );
const SplitterRegistration = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/customers/SplitterRegistration'
  );
const SearchCustomer = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/customers/SearchCustomer'
  );
const SearchSplitter = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/customers/SearchSplitter'
  );
const SearchSeller = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/sellers/SearchSeller'
  );
const ChangeSellerHolder = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/sellers/ChangeSellerHolder'
  );
const SplitterInformation = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/customers/customer-details/SplitterInformation'
  );
// Link Payment
const SearchLinkPayment = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/linkpayment/SearchLinkPayment'
  );
const ViewLinkPayment = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/linkpayment/view/LinkPayment'
  );
const NewLinkPayment = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/linkpayment/LinkPayment'
  );

const SelectCustomer = () =>
  import(
    /* webpackChunkName: "group-customer" */ '@/components/commercial/customers/bankslip/SelectCustomer'
  );

// Access Control
const EditRole = () =>
  import(
    /* webpackChunkName: "group-role" */ '@/components/access-control/roles/EditRole'
  );
const SearchRoles = () =>
  import(
    /* webpackChunkName: "group-role" */ '@/components/access-control/roles/SearchRoles'
  );
const ShowRole = () =>
  import(
    /* webpackChunkName: "group-role" */ '@/components/access-control/roles/ShowRole'
  );
const SearchUsers = () =>
  import(
    /* webpackChunkName: "group-user" */ '@/components/access-control/users/SearchUsers'
  );
const EditUser = () =>
  import(
    /* webpackChunkName: "group-user" */ '@/components/access-control/users/EditUser'
  );
const ShowUser = () =>
  import(
    /* webpackChunkName: "group-user" */ '@/components/access-control/users/ShowUser'
  );
const Devices = () =>
  import(
    /* webpackChunkName: "group-user" */ '@/components/access-control/devices/DevicesList'
  );

// Financial
const NewAnticipation = () =>
  import(
    /* webpackChunkName: "group-anticipation" */ '@/components/financial/anticipations/NewAnticipation'
  );
const ShowAnticipation = () =>
  import(
    /* webpackChunkName: "group-anticipation" */ '@/components/financial/anticipations/ShowAnticipation'
  );
const DeactivationSearchSpcSerasa = () =>
  import(
    /* webpackChunkName: "group-serasa" */ '@/components/financial/credit-protection/Deactivation'
  );
const SalesHistory = () =>
  import(
    /* webpackChckName: "group-sales" */ '@/components/financial/sales/SalesHistory'
  );
const SellersSalesHistory = () =>
  import(
    /* webpackChckName: "group-sales" */ '@/components/financial/sales/SellersSalesHistory'
  );

// Payments
const PaymentHistory = () =>
  import(
    /* webpackChunkName: "group-payments" */ '@/components/financial/payments/history/PaymentHistory'
  );
const PaymentDetails = () =>
  import(
    /* webpackChunkName: "group-payments" */ '@/components/financial/payments/history/PaymentDetails'
  );
const ChangeBankSlip = () =>
  import(
    /* webpackChunkName: "group-payments" */ '@/components/financial/payments/history/ChangeBankSlip'
  );
const Invoice = () =>
  import(
    /* webpackChunkName: "group-transfers" */ '@/components/financial/payments/history/Invoice'
  );

// Account
const Presets = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/presets/Holder'
  );
const Customize = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/presets/Customize'
  );
const AppSettings = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/presets/AppSettings'
  );
const ChangePassword = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/presets/ChangePassword'
  );
const FeeList = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/FeeList'
  );
const BalanceExtract = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/balance-extract/BalanceExtract'
  );
const Transference = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/Transference'
  );
const SplitterTransference = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/SplitterTransference'
  );
const UpcomingReleases = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/UpcomingReleases'
  );
const ConfigureLimits = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/ConfigureLimits'
  );
const Preferences = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/Preferences'
  );
const ProofOfIncome = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/account/ProofOfIncome'
  );

// Activation
const FirstStep = () =>
  import(
    /* webpackChunkName: "group-account" */ '@/components/activation/FirstStep'
  );

// Bills Payment
const ListBillsPayment = () =>
  import(
    /* webpackChunkName: "group-billsPayment" */ '@/components/financial/billsPayment/ListPayments'
  );
const DetailsBillsPayment = () =>
  import(
    /* webpackChunkName: "group-billsPayment" */ '@/components/financial/billsPayment/DetailsBillsPayment'
  );
const NewPayment = () =>
  import(
    /* webpackChunkName: "group-billsPayment" */ '@/components/financial/billsPayment/NewBillPayment'
  );

// PIX SECTION
const ListPixKeys = () =>
  import(/* webpackChunkName: "group-pix" */ '@/components/pix/keys/ListKeys');
const ListPixTransactions = () =>
  import(
    /* webpackChunkName: "group-pix" */ '@/components/pix/transactions/ListPixTransactions'
  );
const Limits = () =>
  import(/* webpackChunkName: "group-pix" */ '@/components/pix/limits/limits');
const Claims = () =>
  import(/* webpackChunkName: "group-pix" */ '@/components/pix/claims/Claims');
const PixForm = () =>
  import(
    /* webpackChunkName: "group-pix" */ '@/components/pix/transactions/bill/pix-forms/PixForm'
  );
const PixResume = () =>
  import(
    /* webpackChunkName: "group-pix" */ '@/components/pix/transactions/bill/pix-forms/PixResumeForm'
  );
const ListQRCodes = () =>
  import(
    /* webpackChunkName: "group-pix" */ '@/components/pix/qrcodes/ListQRCodes'
  );
const QRCodeDetails = () =>
  import(
    /* webpackChunkName: "group-pix" */ '@/components/pix/qrcodes/QrCodeDetails'
  );

const AlterBankSlip = () =>
    import(
      /* webpackChunkName: "group-pix" */ '@/components/bank-slip/limits'
    );

export const appRoutes = {
  path: '',
  component: Layout,
  children: [
    {
      path: '',
      name: 'root',
      component: Welcome,
      meta: {
        groupId: 0,
        group: '',
        functionalityTags: [],
        breadcrumb: 'Inicial',
      },
    },
    {
      path: 'indicadores',
      name: 'charts',
      component: Charts,
      meta: {
        groupId: 1,
        group: '',
        functionalityTags: ['indicadores'],
        breadcrumb: 'Indicadores',
        title: 'Pagcerto - NetBanking - Indicadores',
      },
    },
    // {
    //   path: 'antecipacao',
    //   name: 'anticipation',
    //   component: NewAnticipation,
    //   meta: {
    //     groupId: 2,
    //     group: 'Minhas transações',
    //     functionalityTags: [
    //       'minhas-transacoes',
    //       'minhas-transacoes-antecipacao-recebiveis',
    //     ],
    //     breadcrumb: 'Antecipação de recebíveis',
    //     title: 'Pagcerto - NetBanking - Antecipação de recebíveis',
    //   },
    // },
    // {
    //   path: 'antecipacao/:anticipationId',
    //   name: 'show-anticipation',
    //   component: ShowAnticipation,
    //   meta: {
    //     groupId: 2,
    //     group: 'Minhas transações',
    //     functionalityTags: [
    //       'minhas-transacoes',
    //       'minhas-transacoes-antecipacao-recebiveis',
    //     ],
    //     breadcrumb: 'Detalhes da antecipação',
    //     title: 'Pagcerto - NetBanking - Detalhes da antecipação',
    //   },
    // },
    {
      path: 'dirf',
      name: 'proof-of-income',
      component: ProofOfIncome,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: ['conta'],
        breadcrumb: 'DIRF',
        title: 'Pagcerto - Conta - DIRF',
      },
    },
    {
      path: 'cliente',
      name: 'customer',
      component: SearchCustomer,
      meta: {
        groupId: 3,
        group: 'Cadastros',
        breadcrumb: 'Cliente',
        functionalityTags: ['clientes'],
        title: 'Pagcerto - NetBanking - Cliente',
      },
    },
    {
      path: 'splitter',
      name: 'splitter',
      component: SearchSplitter,
      meta: {
        groupId: 143,
        group: 'Cadastros',
        functionalityTags: ['splitter', 'splitter-cadastros'],
        breadcrumb: 'Splitter',
        title:
          'Pagcerto - NetBanking - Transferir para conta bancária do splitter',
      },
    },
    {
      path: 'titular',
      name: 'seller',
      component: SearchSeller,
      meta: {
        groupId: 3,
        group: 'Cadastros',
        functionalityTags: [],
        breadcrumb: 'Titular',
        title: 'Pagcerto - NetBanking - Títular',
      },
    },
    {
      path: 'titular/:holderId/atualizar-representante',
      name: 'change-seller-holder',
      component: ChangeSellerHolder,
      meta: {
        groupId: 3,
        group: 'Cadastros',
        functionalityTags: [],
        breadcrumb: 'Atualizar representante',
        title: 'Pagcerto - NetBanking - Atualizar representante',
      },
    },
    {
      path: 'splitter/novo',
      name: 'new-splitter',
      component: SplitterRegistration,
      meta: {
        groupId: 14,
        group: 'Cadastros',
        functionalityTags: ['splitter', 'splitter-cadastros'],
        breadcrumb: 'Novo splitter',
        title: 'Pagcerto - NetBanking - Novo splitter',
      },
    },
    {
      path: 'splitter/:splitterId/editar',
      name: 'edit-splitter',
      component: SplitterRegistration,
      meta: {
        groupId: 14,
        group: 'Cadastros',
        functionalityTags: ['splitter', 'splitter-cadastros'],
        breadcrumb: 'Editar splitter',
        title: 'Pagcerto - NetBanking - Editar splitter',
      },
    },
    {
      path: 'splitter/:splitterId/detalhes',
      name: 'splitter-details',
      component: SplitterInformation,
      meta: {
        groupId: 14,
        group: 'Cadastros',
        functionalityTags: ['splitter', 'splitter-cadastros'],
        breadcrumb: 'Detalhes splitter',
        title: 'Pagcerto - NetBanking - Detalhes splitter',
      },
    },
    {
      path: 'cliente/novo',
      name: 'new-customer',
      component: CustomerRegistration,
      meta: {
        groupId: 3,
        group: 'Cadastros',
        functionalityTags: ['clientes'],
        breadcrumb: 'Novo usuário',
        title: 'Pagcerto - NetBanking - Novo usuário',
      },
    },
    {
      path: 'cliente/:customerId/editar',
      name: 'edit-customer',
      component: CustomerRegistration,
      meta: {
        groupId: 3,
        group: 'Cadastros',
        functionalityTags: ['clientes'],
        breadcrumb: 'Editar usuário',
        title: 'Pagcerto - NetBanking - Editar usuário',
      },
    },
    {
      path: 'cliente/:customerId/detalhes-pessoa-fisica',
      name: 'person-customer-details',
      component: PhysicalCustomerDetails,
      meta: {
        groupId: 3,
        group: 'Cadastros',
        functionalityTags: ['clientes'],
        breadcrumb: 'Detalhes pessoa física',
        title: 'Pagcerto - NetBanking - Detalhes pessoa física',
      },
    },
    {
      path: 'cliente/:customerId/detalhes-pessoa-juridica',
      name: 'corporative-customer-details',
      component: CorporateCustomerDetails,
      meta: {
        groupId: 3,
        group: 'Cadastros',
        functionalityTags: ['clientes'],
        breadcrumb: 'Detalhes pessoa jurídica',
        title: 'Pagcerto - NetBanking - Detalhes pessoa jurídica',
      },
    },
    {
      path: 'perfis-usuarios',
      name: 'roles',
      component: SearchRoles,
      meta: {
        groupId: 4,
        group: 'Controle de acesso',
        functionalityTags: [
          'controle-acesso',
          'controle-acesso-perfis-usuarios',
        ],
        breadcrumb: 'Perfis de usuários',
        title: 'Pagcerto - NetBanking - Perfis de usuários',
      },
    },
    {
      path: 'perfis-usuarios/novo',
      name: 'new-role',
      component: EditRole,
      meta: {
        groupId: 4,
        group: 'Controle de acesso',
        functionalityTags: [
          'controle-acesso',
          'controle-acesso-perfis-usuarios',
        ],
        breadcrumb: 'Criar novo perfil',
        title: 'Pagcerto - NetBanking - Criar novo perfil',
      },
    },
    {
      path: 'perfis-usuarios/:roleId',
      name: 'show-role',
      component: ShowRole,
      meta: {
        groupId: 4,
        group: 'Controle de acesso',
        functionalityTags: [
          'controle-acesso',
          'controle-acesso-perfis-usuarios',
        ],
        breadcrumb: 'Detalhes do perfil',
        title: 'Pagcerto - NetBanking - Detalhes do perfil',
      },
    },
    {
      path: 'perfis-usuarios/:roleId/editar',
      name: 'edit-role',
      component: EditRole,
      meta: {
        groupId: 4,
        group: 'Controle de acesso',
        functionalityTags: [
          'controle-acesso',
          'controle-acesso-perfis-usuarios',
        ],
        breadcrumb: 'Editar perfil',
        title: 'Pagcerto - NetBanking - Editar perfil',
      },
    },
    {
      path: 'protecao-de-credito',
      name: 'credit-protection',
      component: DeactivationSearchSpcSerasa,
      meta: {
        groupId: 5,
        group: 'Serviços',
        functionalityTags: [],
        breadcrumb: 'Consulta SPC/Serasa',
        title: 'Pagcerto - NetBanking - Consulta SPC/Serasa',
      },
    },
    {
      path: 'usuario',
      name: 'user',
      component: SearchUsers,
      meta: {
        groupId: 7,
        group: 'Controle de acesso',
        breadcrumb: 'Usuários',
        functionalityTags: ['controle-acesso', 'controle-acesso-usuarios'],
        title: 'Pagcerto - NetBanking - Usuários',
      },
    },
      {
      path: 'dispositivo',
      name: 'device',
      component: Devices,
      meta: {
        groupId: 7,
        group: 'Controle de acesso',
        breadcrumb: 'Dispositivos',
        functionalityTags: ['controle-acesso', 'controle-acesso-usuarios'],
        title: 'Pagcerto - NetBanking - Dispositivos',
      },
    },
    {
      path: 'usuario/novo',
      name: 'new-user',
      component: EditUser,
      meta: {
        groupId: 7,
        group: 'Controle de acesso',
        functionalityTags: ['controle-acesso', 'controle-acesso-usuarios'],
        breadcrumb: 'Cadastrar usuário',
        title: 'Pagcerto - NetBanking - Cadastrar usuário',
      },
    },
    {
      path: 'usuario/:userId/editar',
      name: 'edit-user',
      component: EditUser,
      meta: {
        groupId: 7,
        group: 'Controle de acesso',
        functionalityTags: ['controle-acesso', 'controle-acesso-usuarios'],
        breadcrumb: 'Editar usuário',
        title: 'Pagcerto - NetBanking - Editar usuário',
      },
    },
    {
      path: 'usuario/:userId/detalhes',
      name: 'show-user',
      component: ShowUser,
      meta: {
        groupId: 7,
        group: 'Controle de acesso',
        functionalityTags: ['controle-acesso', 'controle-acesso-usuarios'],
        breadcrumb: 'Detalhes do usuário',
        title: 'Pagcerto - NetBanking - Detalhes do usuário',
      },
    },
    {
      path: 'historico-de-pagamentos',
      name: 'payment-history',
      component: PaymentHistory,
      meta: {
        groupId: 8,
        group: 'Minhas transações',
        functionalityTags: [
          'minhas-transacoes',
          'minhas-transacoes-cartao-boleto',
        ],
        breadcrumb: 'Consulta de pagamentos',
        title: 'Pagcerto - NetBanking - Consulta de pagamentos',
      },
    },
    {
      path: 'detalhes-de-pagamentos/:paymentId',
      name: 'payment-details',
      component: PaymentDetails,
      meta: {
        groupId: 8,
        group: 'Minhas transações',
        functionalityTags: [
          'minhas-transacoes',
          'minhas-transacoes-cartao-boleto',
        ],
        breadcrumb: 'Detalhes de pagamento',
        title: 'Pagcerto - NetBanking - Detalhes de pagamento',
      },
    },
    {
      path: 'comprovante/:paymentId',
      name: 'payment-invoice',
      component: Invoice,
      meta: {
        groupId: 8,
        group: 'Minhas transações',
        functionalityTags: [
          'minhas-transacoes',
          'minhas-transacoes-cartao-boleto',
        ],
        breadcrumb: 'Comprovante',
        title: 'Pagcerto - NetBanking - Comprovante',
      },
    },
    {
      path: 'substituir-boleto/:bankSlipNumber/:maxDiscount',
      name: 'change-bankslip',
      component: ChangeBankSlip,
      meta: {
        groupId: 8,
        group: 'Minhas transações',
        functionalityTags: [
          'minhas-transacoes',
          'minhas-transacoes-cartao-boleto',
        ],
        breadcrumb: 'Substituir boleto',
        title: 'Pagcerto - NetBanking - Substituir boleto',
      },
    },
    {
      path: 'configuracoes-da-conta',
      name: 'presets',
      component: Presets,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: [],
        breadcrumb: 'Configurações da conta',
        title: 'Pagcerto - NetBanking - Configurações da conta',
      },
    },
    {
      path: 'customize',
      name: 'customize',
      component: Customize,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: [],
        breadcrumb: 'Customize',
        title: 'Pagcerto - NetBanking - Customize',
      },
    },
    {
      path: 'settings',
      name: 'settings',
      component: AppSettings,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: [],
        breadcrumb: 'Configurações',
        title: 'Pagcerto - NetBanking - Configurações',
      },
    },
    {
      path: 'alterar-de-senha',
      name: 'change-password',
      component: ChangePassword,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: [],
        breadcrumb: 'Alterar senha',
        title: 'Pagcerto - NetBanking - Alterar senha',
      },
    },
    {
      path: 'minhas-taxas',
      name: 'fee-list',
      component: FeeList,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: [],
        breadcrumb: 'Minhas taxas',
        title: 'Pagcerto - NetBanking - Minhas taxas',
      },
    },
    {
      path: 'transacao-no-boleto',
      name: 'bankslip',
      component: SelectCustomer,
      meta: {
        groupId: 10,
        group: 'Transação',
        functionalityTags: ['transacao-boleto'],
        breadcrumb: 'Boleto',
        title: 'Pagcerto - NetBanking - Boleto',
      },
    },
    {
      path: 'ative-agora',
      name: 'activation-start',
      component: FirstStep,
      meta: {
        groupId: 11,
        group: 'Aprovação de conta',
        functionalityTags: [],
        breadcrumb: 'Início',
        title: 'Pagcerto - NetBanking - Aprovação de conta',
      },
    },
    {
      path: 'historico-de-transacoes-por-estabelecimento',
      name: 'sellers-sales-history',
      component: SellersSalesHistory,
      meta: {
        groupId: 2,
        group: 'Minhas transações',
        functionalityTags: [],
        breadcrumb: 'Cartão e boleto (por Estabelecimento)',
        title: 'Pagcerto - NetBanking - Cartão e boleto',
      },
    },
    {
      path: 'historico-de-transacoes',
      name: 'sales-history',
      component: SalesHistory,
      meta: {
        groupId: 2,
        group: 'Minhas transações',
        functionalityTags: [
          'minhas-transacoes',
          'minhas-transacoes-cartao-boleto',
        ],
        breadcrumb: 'Cartão e boleto',
        title: 'Pagcerto - NetBanking - Cartão e boleto',
      },
    },
    {
      path: 'link-cobranca',
      name: 'linkpayment',
      component: SearchLinkPayment,
      meta: {
        groupId: 13,
        group: 'Minhas transações',
        functionalityTags: [
          'minhas-transacoes',
          'minhas-transacoes-links-cobranca',
        ],
        breadcrumb: 'Link de cobrança',
      },
    },
    {
      path: 'link-cobranca/:linkPaymentId/visualizar',
      name: 'linkpayment-visualizar',
      component: ViewLinkPayment,
      meta: {
        groupId: 13,
        group: 'Minhas transações',
        functionalityTags: [
          'minhas-transacoes',
          'minhas-transacoes-links-cobranca',
        ],
        breadcrumb: 'Visualizar link de cobrança',
      },
    },
    {
      path: 'link-cobranca/novo',
      name: 'new-linkpayment',
      component: NewLinkPayment,
      meta: {
        groupId: 13,
        group: 'Minhas transações',
        functionalityTags: [
          'minhas-transacoes',
          'minhas-transacoes-links-cobranca',
        ],
        breadcrumb: 'Novo link de cobrança',
      },
    },
    {
      path: 'saldo-extrato',
      name: 'balance-extract',
      component: BalanceExtract,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: ['conta', 'conta-saldo-extrato'],
        breadcrumb: 'Saldo e Extrato',
        title: 'Pagcerto - Conta - Saldo e Extrato',
      },
    },
    {
      path: 'transferencia',
      name: 'transference',
      component: Transference,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: ['conta', 'conta-transferencias'],
        breadcrumb: 'Transferência',
        title: 'Pagcerto - Conta - Transferência',
      },
    },
    {
      path: 'transferencia-splitter',
      name: 'splitter-transference',
      component: SplitterTransference,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: ['splitter', 'splitter-transferencias'],
        breadcrumb: 'Transferência Splitter',
        title: 'Pagcerto - Conta - Transferência Splitter',
      },
    },
    {
      path: 'proximos-lancamentos',
      name: 'upcoming-releases',
      component: UpcomingReleases,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: ['conta', 'conta-proximos-lancamentos'],
        breadcrumb: 'Próximos lançamentos',
        title: 'Pagcerto - Conta - Próximos lançamentos',
      },
    },
    {
      path: 'configurar-limites',
      name: 'configure-limits',
      component: ConfigureLimits,
      meta: {
        groupId: 9,
        group: 'Conta',
        functionalityTags: ['conta', 'conta-configurar-limites'],
        breadcrumb: 'Configuração de limites',
        title: 'Pagcerto - Conta - Configuração de limites',
      },
    },
    // {
    //   path: 'preferencias',
    //   name: 'preferences',
    //   component: Preferences,
    //   meta: {
    //     groupId: 9,
    //     group: 'Conta',
    //     breadcrumb: 'Preferências',
    //     title: 'Pagcerto - Conta - Preferências',
    //   },
    // },
    {
      path: 'pagamentos',
      name: 'bills-payment',
      component: ListBillsPayment,
      meta: {
        group: 'Meus Pagamentos',
        functionalityTags: ['conta', 'conta-pagamento-contas'],
        breadcrumb: 'Pagamentos',
        title: 'Pagcerto - Pagamento de Contas',
      },
    },
    {
      path: 'pagamentos/detalhes/:paymentId',
      name: 'details-bills-payment',
      component: DetailsBillsPayment,
      meta: {
        group: 'Meus Pagamentos',
        functionalityTags: ['conta', 'conta-pagamento-contas'],
        breadcrumb: 'Detalhes',
        title: 'Pagcerto - Pagamento de Contas',
      },
    },
    {
      path: 'pagamentos/novo',
      name: 'new-bills-payment',
      component: NewPayment,
      meta: {
        group: 'Meus Pagamentos',
        functionalityTags: ['conta', 'conta-pagamento-contas'],
        breadcrumb: 'Novo',
        title: 'Pagcerto - Pagamento de Contas',
      },
    },
    /* PIX SECTION */
    {
      path: 'pix',
      name: 'pix',
      component: ListPixKeys,
      meta: {
        group: 'PIX',
        functionalityTags: ['pix', 'pix-minhas-chaves'],
        breadcrumb: 'Minhas chaves',
        title: 'Pagcerto - PIX',
      },
    },
    {
      path: 'ordens-pagamento-pix',
      name: 'pix-order-payments',
      component: ListPixTransactions,
      meta: {
        group: 'PIX',
        functionalityTags: ['pix', 'pix-minhas-movimentacoes'],
        breadcrumb: 'Ordens pagamento',
        title: 'Pagcerto - Ordens de pagamento PIX',
      },
    },
    {
      path: 'ordens-pagamento-pix/cobranca-pix',
      name: 'new-pix-charge',
      component: PixForm,
      meta: {
        groupId: 3,
        group: 'PIX',
        functionalityTags: ['pix', 'pix-minhas-movimentacoes'],
        breadcrumb: 'Cobrança',
        title: 'Pagcerto - NetBanking - PIX',
      },
    },
    {
      path: 'ordens-pagamento-pix/pix-resumo',
      name: 'pix-resume',
      component: PixResume,
      meta: {
        groupId: 3,
        group: 'PIX',
        functionalityTags: ['pix', 'pix-minhas-movimentacoes'],
        breadcrumb: 'Resumo PIX',
        title: 'Pagcerto - NetBanking - Resumo PIX',
      },
    },
    {
      path: 'limites-pix',
      name: 'pix-limits',
      component: Limits,
      meta: {
        group: 'PIX',
        functionalityTags: ['pix', 'pix-limites'],
        breadcrumb: 'Limites PIX',
        title: 'Pagcerto - Limites PIX',
      },
    },
    {
      path: 'portabilidade-pix',
      name: 'pix-claims',
      component: Claims,
      meta: {
        group: 'PIX',
        functionalityTags: ['pix', 'pix-minhas-chaves'],
        breadcrumb: 'Portabilidades e Reivindicações',
        title: 'Pagcerto - Portabilidade PIX',
      },
    },
    {
      path: 'qrcodes-pix',
      name: 'qrcodes',
      component: ListQRCodes,
      meta: {
        group: 'PIX',
        functionalityTags: ['pix', 'pix-meus-qrcodes'],
        breadcrumb: 'Meus QR Codes',
        title: 'Pagcerto - QR Codes',
      },
    },
    {
      path: 'detalhes-qrcode',
      name: 'qrcode-details',
      component: QRCodeDetails,
      meta: {
        group: 'PIX',
        functionalityTags: ['pix', 'pix-meu-qrcode'],
        breadcrumb: 'Meu QR Code',
        title: 'Pagcerto - Meu QR Code',
      },
    },
    /* Bank Slip */
    {
      path: 'limites-boleto',
      name: 'limites',
      component: AlterBankSlip,
      meta: {
        group: 'Limites Boleto',
        breadcrumb: 'Limites',
        title: 'Pagcerto - Limites',
      },
    },
    {
      path: '**',
      component: NotFound,
    },
  ],
};
