import { withParams } from 'vuelidate/lib';
import { noAccents } from '@/functions/noAccents';

const password = withParams({ type: 'password' }, value => {
  var str = noAccents(value);

  if (!str) return true;

  const pattern = /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  var regex = new RegExp(pattern);
  return regex.test(str);
});

export { password };
