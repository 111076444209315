<template>
  <div>
    <app-alert />
    <app-spinner />
    <div class="container login-container">
      <form @submit.prevent="sendEmailRecovery" autocomplete="off">
        <WhiteLabel class="d-flex justify-content-around">
          <div class="login-form">
            <div class="pb-5">
              <WhiteLabel>
                <img
                  class="login-img"
                  :src="layout.negativeLogo || layout.logo"
                />
              </WhiteLabel>
              <img
                v-if="!layout.logo && !layout.negativeLogo"
                class="login-img"
                src="https://cdn.pagcerto.com.br/img/logo/logo-branco.png"
              />
            </div>
            <div class="form-group pb-4">
              <div class="input-group">
                <span class="input-group-addon login p-2 px-3">
                  <svg
                    id="User"
                    data-name="User"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 60 60"
                  >
                    <g class="cls-1">
                      <path
                        class="cls-2"
                        d="M56.35,60H3.66a3.45,3.45,0,0,1-2.5-1.09,3.4,3.4,0,0,1-.89-2.54,29.82,29.82,0,0,1,59.47,0,3.39,3.39,0,0,1-.89,2.54A3.44,3.44,0,0,1,56.35,60ZM30,31.62a27,27,0,0,0-26.88,25,.46.46,0,0,0,.13.38.52.52,0,0,0,.4.18H56.35a.52.52,0,0,0,.4-.18.5.5,0,0,0,.14-.38A27,27,0,0,0,30,31.62Z"
                      />
                    </g>
                    <g class="cls-1">
                      <path
                        class="cls-2"
                        d="M30,31.56A15.83,15.83,0,1,1,45.84,15.73,15.85,15.85,0,0,1,30,31.56Zm0-28.8a13,13,0,1,0,13,13A13,13,0,0,0,30,2.76Z"
                      />
                    </g>
                  </svg>
                </span>
                <input
                  type="text"
                  class="form-control input-login"
                  autofocus
                  v-model="username"
                  @input="$v.username.$touch()"
                  placeholder="E-mail cadastrado"
                />
              </div>
              <small class="text-danger" v-if="usernameIsInvalid">
                <b>* E-mail inválido.</b>
              </small>
            </div>
            <div class="form-group pb-5">
              <vue-recaptcha
                loadRecaptchaScript
                ref="recaptcha"
                :sitekey="recaptcha.siteKey"
                @verify="onVerify($event)"
                @expired="onExpired"
              >
              </vue-recaptcha>
              <small
                class="text-danger"
                v-if="this.$v.recaptcha.$dirty && !recaptcha.token"
              >
                <b>* reCaptcha é obrigatória.</b>
              </small>
            </div>
            <div class="form-group pb-5">
              <button
                type="submit"
                class="btn btn-block btn-grass text-uppercase"
              >
                Enviar e-mail
              </button>
            </div>
            <div
              class="row text-white font-weight-light justify-content-center"
            >
              <div class="col-auto">
                <router-link
                  class="text-white text-10pt"
                  href="#"
                  :to="'/acessar'"
                  >Acessar minha conta</router-link
                >
              </div>
            </div>
          </div>
        </WhiteLabel>
      </form>
    </div>
    <WhiteLabel>
      <div class="footer">
        <small class="text-uppercase">
          <p class="text-center font-weight-light text-white pt-2">
            Copyright &#9400; 2020 Pagcerto. Todos os direitos reservados.
          </p>
        </small>
      </div>
    </WhiteLabel>
  </div>
</template>

<script>
import AccountApi from '@/services/AccountApi';
import { required, email } from 'vuelidate/lib/validators';
import SendEmailRecoveryError from '@/errors/SendEmailRecoveryError';
import AppAlert from '@/components/theme/Alert';
import AppSpinner from '@/components/theme/Spinner';
import WhiteLabel from '@/components/shared/WhiteLabel';
import LayoutApi from '@/services/v2/LayoutApi';
import { mapActions, mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'SendEmailRecovery',
  components: {
    AppSpinner,
    AppAlert,
    WhiteLabel,
    VueRecaptcha,
  },

  data: () => ({
    title: 'Send e-mail recovery',
    username: null,
    subdomainUrl: null,
    logoUrl: `${process.env.cdn}/img/logos/logo-white.png`,
    applicationId: null,
    layout: {
      primaryColor: null,
      secondaryColor: null,
      tertiaryColor: null,
      logo: null,
      negativeLogo: null,
    },
    readyToRender: false,
    recaptcha: {
      siteKey: '6LdO8AgUAAAAAFhJc78nFBR5S-Q9h1vQDZ3D2wZx',
      token: null,
    },
  }),

  async beforeMount() {
    const api = new LayoutApi();
    const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
    const subdomain = window.location.origin.match(subdomainRegex);

    if (!subdomain || subdomain[1] === 'gestao') {
      this.subdomain = null;
      this.resetLayout();

      this.readyToRender = true;
      return;
    }

    this.subdomain = subdomain ? subdomain[1] : null;
    api
      .fetch(subdomain[1])
      .then((response) => {
        this.layout = response;

        window.localStorage.setItem('primaryColor', response.primaryColor);
        window.localStorage.setItem('secondaryColor', response.secondaryColor);
        window.localStorage.setItem('tertiaryColor', response.tertiaryColor);
        window.localStorage.setItem('applicationId', response.applicationId);

        if (response.applicationName) {
          this.setApplicationName(response.applicationName);
          document.title = `${response.applicationName} - NetBanking`;
        } else {
          document.title = 'Pagcerto - NetBanking';
        }

        let link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement('link');
        link.type = 'image/png';
        link.rel = 'icon';
        link.href = response.logo;
        document.getElementsByTagName('head')[0].appendChild(link);

        this.readyToRender = true;
      })
      .catch(() => {
        this.readyToRender = true;
      });
  },

  computed: {
    usernameIsInvalid() {
      return this.$v.username.$invalid && this.$v.username.$dirty;
    },
  },

  methods: {
    ...mapActions({
      auth: 'account/login',
      askWhoAmI: 'account/askWhoAmI',
      resetLayout: 'layout/resetLayout',
      exit: 'account/logout',
      setApplicationName: 'layout/setApplicationName',
    }),
    async sendEmailRecovery() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      if (!this.recaptcha.token) {
        this.$v.recaptcha.$touch();
        return;
      }

      const api = new AccountApi();
      this.$bus.$emit('spinner-run');
      this.setSubdomainUrl();

      if (localStorage.getItem('applicationId') != null) {
        this.applicationId = localStorage.getItem('applicationId');
      } else {
        this.applicationId = process.env.VUE_APP_APPLICATION_ID;
      }

      const response = await api.sendEmailRecovery(
        this.username,
        this.subdomainUrl,
        this.applicationId
      );
      if (response.status === 204) {
        const title = 'Pronto!';
        this.$alert.onClose = async () => this.$router.push('/');
        this.$alert.info(title, 'E-mail enviado com sucesso.');
        this.$bus.$emit('spinner-stop');
      } else if (response.status === 422) {
        const title = 'Ops';
        const modelError = new SendEmailRecoveryError(response.data);
        const error = modelError.getMessage();
        this.$bus.$emit('spinner-stop');
        this.$alert.error(title, `${error}`);
        this.$router.push('/');
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.$router.push('/');
      }
    },
    setSubdomainUrl() {
      const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
      const subdomain = window.location.origin.match(subdomainRegex);
      var enviroment = process.env.VUE_APP_LIGHTBOX;

      switch (enviroment) {
        case 'dev':
          enviroment = '.dev';
          break;
        case 'homologation':
          enviroment = '.homol';
          break;
        case 'sandbox':
          enviroment = '.sandbox';
          break;
        default:
          enviroment = '';
      }

      if (enviroment === '.sandbox' || enviroment === '') {
        this.subdomainUrl = `https://${subdomain[1]}${enviroment}.pagcerto.com.br/criar-nova-senha/`;
      } else {
        this.subdomainUrl = `http://${subdomain[1]}${enviroment}.pagcerto.com.br/criar-nova-senha/`;
      }
    },
    onVerify(token) {
      this.recaptcha.token = token;
    },
    onExpired() {
      this.recaptcha.token = null;
    },
  },

  validations: {
    username: {
      required,
      email,
    },
    recaptcha: {
      required,
    },
  },

  watch: {
    username: function () {
      this.username = this.username.replace(/ /g, '');
    },
  },
};
</script>
