<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RemoteAccess',
  async mounted() {
    await this.signinAs();
  },
  methods: {
    async signinAs() {
      const match = /.*\/acesso-remoto-chargeback\?token=(.*)/.exec(
        window.location.href
      );
      this.$bus.$emit('spinner-run');
      await this.auth({ token: match[1] });
      await this.askWhoAmI();
      this.$router.push({ name: 'payment-history' });
      this.$bus.$emit('spinner-stop');
    },
    ...mapActions({
      auth: 'account/login',
      askWhoAmI: 'account/askWhoAmI',
    }),
  },
};
</script>
