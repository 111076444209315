import axios from 'axios';

class LayoutApi {
  async fetch(subdomain) {
    const url = subdomain
      ? `${process.env.VUE_APP_LAYOUT_API_URL}/layout/${subdomain}`
      : `${process.env.VUE_APP_LAYOUT_API_URL}/layout`;

    const result = await axios.get(url);

    return result.data;
  }

  async create({ primaryColor, secondaryColor, tertiaryColor, logo, negativeLogo }) {
    const url = `${process.env.VUE_APP_LAYOUT_API_URL}/layout`;
    const modelData = new FormData();
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    modelData.append('primaryColor', primaryColor);
    modelData.append('secondaryColor', secondaryColor);
    modelData.append('tertiaryColor', tertiaryColor);
    modelData.append('logo', logo);
    modelData.append('negativeLogo', negativeLogo);

    const result = await axios.post(url, modelData, header);

    return result.data;
  }
}

export default LayoutApi;
