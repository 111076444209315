import LayoutApi from '@/services/v2/LayoutApi';
import { FETCHEDLAYOUT, UPDATEDLAYOUT, RESETLAYOUT, SETNAVIGATION, SETAPPLICATIONNAME } from './mutations-type';

const setLocalStorageLayout = ({ primaryColor, secondaryColor, tertiaryColor, applicationId }) => {
  if (primaryColor) {
    window.localStorage.setItem('primaryColor', primaryColor);
    window.localStorage.setItem('secondaryColor', secondaryColor);
    window.localStorage.setItem('tertiaryColor', tertiaryColor);
  }
  window.localStorage.setItem('applicationId', applicationId);
};

const moduleLayout = {
  namespaced: true,
  state: {
    applicationName: 'Pagcerto',
    primaryColor: '#ff7606',
    secondaryColor: '#fa3645',
    tertiaryColor: '#ff7606',
    logo: null,
    negativeLogo: null,
    applicationId: '',
  },
  actions: {
    async fetchLayout({ commit }, subdomain) {
      const api = new LayoutApi();

      if (subdomain == undefined || subdomain == '') return;

      var newLayout = await api.fetch(subdomain);

      commit(FETCHEDLAYOUT, { layout: newLayout });
    },
    updateLayout({ commit }, layout) {
      const api = new LayoutApi();

      api.create(layout).then(() => {
        commit(UPDATEDLAYOUT, { layout });
      });
    },
    resetLayout({ commit }) {
      commit(RESETLAYOUT);
    },
    setNavigation({ commit }, navigation) {
      commit(SETNAVIGATION, { navigation });
    },
    setApplicationName({ commit }, applicationName) {
      commit(SETAPPLICATIONNAME, { applicationName });
    },
  },
  mutations: {
    [FETCHEDLAYOUT](state, { layout }) {
      setLocalStorageLayout(layout);
      state = layout;
    },
    [UPDATEDLAYOUT](state, { layout }) {
      setLocalStorageLayout(layout);
    },
    [RESETLAYOUT](state) {
      window.localStorage.removeItem('primaryColor');
      window.localStorage.removeItem('secondaryColor');
      window.localStorage.removeItem('tertiaryColor');
    },
    [SETNAVIGATION](state, { navigation }) {
      window.localStorage.setItem('navigation', navigation);
    },
    [SETAPPLICATIONNAME](state, { applicationName }) {
      window.localStorage.setItem('applicationName', applicationName);
    },
  },
  getters: {
    getLayout(state) {
      return state;
    },
  },
};

export default moduleLayout;
