import { isUndefined } from 'util';

export class Permission {
  name = ''
  grant = 'none'
  readScope = ''
  editingScope = ''
  creatingScope = ''
  grantTypes = ['none', 'readonly', 'edit', 'create']

  grantedScopes() {
    switch (this.grant) {
    case 'readonly': return [this.readScope];
    case 'edit': return [this.readScope, this.editingScope];
    case 'create': return [this.readScope, this.editingScope, this.creatingScope];
    default: return [];
    }
  }

  revokedScopes() {
    switch (this.grant) {
    case 'none': return [this.readScope, this.editingScope, this.creatingScope];
    case 'readonly': return [this.editingScope, this.creatingScope];
    case 'edit': return [this.creatingScope];
    default: return [];
    }
  }

  grantedDependencies() {
    if (isUndefined(this.meta) || this.grant == 'none') {
      return [];
    } else {
      switch (this.grant) {
      case 'readonly': return [this.meta.dependencies.readonly];
      case 'create': return [this.meta.dependencies.readonly, this.meta.dependencies.create];
      default: return [];
      }
    }
  }

  revokedDependencies() {
    if (!isUndefined(this.meta)) {
      switch (this.grant) {
      case 'readonly': return [this.meta.dependencies.create];
      default: return [];
      }
    }
  }
}

export class CustomerPermission extends Permission {
  name = 'business.customers'
  info = {
    title: 'Clientes',
    none: 'Sem acesso',
    readonly: 'Apenas visualizar',
    edit: 'Cadastrar, alterar e visualizar',
    create: '',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class RolePermission extends Permission {
  name = 'account.roles'
  info = {
    title: 'Perfis de usuários',
    none: 'Sem acesso',
    readonly: 'Apenas visualizar',
    edit: 'Cadastrar, alterar e visualizar',
    create: '',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class UserPermission extends Permission {
  name = 'account.users'
  info = {
    title: 'Usuários',
    none: 'Sem acesso',
    readonly: 'Apenas visualizar',
    edit: 'Cadastrar, alterar e visualizar',
    create: '',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class PaymentPermission extends Permission {
  name = 'payments'
  info = {
    title: 'Transações',
    none: 'Sem acesso',
    readonly: 'Visualizar pagamentos',
    edit: '',
    create: 'Vender e visualizar pagamentos',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class AnticipationPermission extends Permission {
  name = 'payments.anticipation'
  info = {
    title: 'Antecipação',
    none: 'Sem acesso',
    readonly: 'Apenas visualizar',
    edit: '',
    create: 'Cadastrar e visualizar',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class TransferPermission extends Permission {
  name = 'payments.transfer'
  info = {
    title: 'Repasse',
    none: 'Sem acesso',
    readonly: 'Apenas visualizar',
    edit: 'Cadastrar e visualizar',
    create: '',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class CardBrandPermission extends Permission {
  name = 'payments.cardBrand'
  info = {
    title: 'Gerenciar bandeiras',
    none: 'Sem acesso',
    readonly: 'Visualizar bandeiras',
    edit: '',
    create: '',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class DashboardPermission extends Permission {
  name = 'paymentAccounts.balance'
  info = {
    title: 'Indicadores',
    none: 'Sem acesso',
    readonly: 'Visualizar indicadores e transferências',
    edit: '',
    create: '',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
  meta = {
    dependencies: {
      readonly: ['payments.statistics.readonly', 'payments.balance.readonly', 'payments.bankTransferFees.readonly', 'paymentAccounts.readonly'],
    },
  }
}

export class PaymentAccountTransferPermission extends Permission {
  name = 'paymentAccounts'
  info = {
    title: 'Transferências',
    none: 'Sem acesso',
    readonly: '',
    edit: '',
    create: 'Solicitar transferências',
  }
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class PaymentAccountTransferManagementPermission extends Permission {
  name = 'paymentAccounts.settings'
  info = {
    title: 'Conta de Pagamento - Preferências',
    none: 'Sem acesso',
    readonly: 'Visualizar preferências de transferências',
    edit: 'Visualizar e editar preferências de transferências',
    create: '',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class DirfPermission extends Permission {
  name = 'payments.reports.taxSummary'
  info = {
    title: 'Conta de Pagamento - DIRF',
    none: 'Sem acesso',
    readonly: 'Visualizar DIRF',
    edit: '',
    create: '',
  }
  readScope = `${this.name}.readonly`
  editingScope = `${this.name}.edit`
  creatingScope = `${this.name}.create`
}

export class PermissionList {
  permissions = [
    new UserPermission(),
    new RolePermission(),
    new PaymentPermission(),
    new CustomerPermission(),
    new AnticipationPermission(),
    new TransferPermission(),
    new CardBrandPermission(),
    new DashboardPermission(),
    new PaymentAccountTransferManagementPermission(),
    new DirfPermission(),
    new PaymentAccountTransferPermission(),
  ]

  grantedScopes() {
    var scopes = [];

    this.permissions.forEach((permission) => {
      let result = permission.grantedScopes();
      if (result !== undefined) {
        scopes = scopes.concat(result);
      }
    });

    this.permissions.forEach((permission) => {
      let result = permission.grantedDependencies();
      if (result !== undefined) {
        scopes = scopes.concat(...result);
      }
    });
    scopes = scopes.filter(Boolean);
    return scopes;
  }

  revokedScopes() {
    var scopes = [];

    this.permissions.forEach((permission) => {
      let result = permission.revokedScopes();
      if (result !== undefined) {
        scopes = scopes.concat(result);
      }
    });

    this.permissions.forEach((permission) => {
      let result = permission.revokedDependencies();
      if (result !== undefined) {
        scopes = scopes.concat(...result);
      }
    });
    scopes = scopes.filter(Boolean);
    return scopes;
  }

  fromScopes(scopes) {
    this.permissions.forEach((permission) => {
      scopes.forEach((scope) => {
        if (permission.grant === 'none') {
          if (permission.creatingScope === scope) {
            permission.grant = 'create';
          } else if (permission.editingScope === scope) {
            permission.grant = 'edit';
          } else if (permission.readScope === scope) {
            permission.grant = 'readonly';
          }
        }
      });
    });
  }
}

export class ScopeParams {
  scopes = []

  constructor(scopes) {
    this.scopes = scopes;
  }
}
