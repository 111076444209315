import { SHOWINPUTNAME, SHOWINPUTCPF, BANKSLIP, CREDIT, DEBIT, PAID, DELAYED, UNAUTHORIZED, PENDING, SEARCHTYPE, CANCELED, CONTESTED, LAST7DAYS, LAST30DAYS, STARTDATE, ENDDATE, CARDBRAND, PAYERTAXDOCUMENT, PAYERNAMEINDEX, PAYERNAME, BANSKSLIPIDINDEX, BANKSLIPID, NSUINDEX, NSU, CARDFINAL, NOTEINDEX, NOTE, INIT } from './mutations-type.js';

const modulePaymentFilters = {
  namespaced: true,
  state: {
    showInputName: false,
    showInputCPF: false,
    bankSlip: false,
    credit: false,
    debit: false,
    paid: false,
    delayed: false,
    unauthorized: false,
    pending: false,
    canceled: false,
    contested: false,
    last7Days: false,
    last30Days: false,
    startDate: '',
    endDate: '',
    cardBrand: '',
    searchType: 'payerTaxDocument',
    payerTaxDocument: '',
    payerNameIndex: 0,
    payerName: '',
    bankslipIdIndex: 0,
    bankslipId: '',
    nsuIndex: 0,
    nsu: '',
    cardFinal: '',
    noteIndex: 0,
    note: '',
  },
  actions: {
    keepShowInputName({ commit, state }, data) {
      commit(SHOWINPUTNAME, data);
    },
    keepShowInputCPF({ commit, state }, data) {
      commit(SHOWINPUTCPF, data);
    },
    keepBankSlip({ commit, state }, data) {
      commit(BANKSLIP, data);
    },
    keepCredit({ commit, state }, data) {
      commit(CREDIT, data);
    },
    keepDebit({ commit, state }, data) {
      commit(DEBIT, data);
    },
    keepPaid({ commit, state }, data) {
      commit(PAID, data);
    },
    keepDelayed({ commit, state }, data) {
      commit(DELAYED, data);
    },
    keepUnauthorized({ commit, state }, data) {
      commit(UNAUTHORIZED, data);
    },
    keepPending({ commit, state }, data) {
      commit(PENDING, data);
    },
    keepCanceled({ commit, state }, data) {
      commit(CANCELED, data);
    },
    keepContested({ commit, state }, data) {
      commit(CONTESTED, data);
    },
    keepLast7Days({ commit, state }, data) {
      commit(LAST7DAYS, data);
    },
    keepLast30Days({ commit, state }, data) {
      commit(LAST30DAYS, data);
    },
    keepStartDate({ commit, state }, data) {
      commit(STARTDATE, data);
    },
    keepEndDate({ commit, state }, data) {
      commit(ENDDATE, data);
    },
    keepCardBrand({ commit, state }, data) {
      commit(CARDBRAND, data);
    },
    keepSearchType({ commit, state }, data) {
      commit(SEARCHTYPE, data);
    },
    keepPayerTaxDocument({ commit, state }, data) {
      commit(PAYERTAXDOCUMENT, data);
    },
    keepPayerNameIndex({ commit, state }, data) {
      commit(PAYERNAMEINDEX, data);
    },
    keepPayerName({ commit, state }, data) {
      commit(PAYERNAME, data);
    },
    keepBankslipIdIndex({ commit, state }, data) {
      commit(BANSKSLIPIDINDEX, data);
    },
    keepBankslipId({ commit, state }, data) {
      commit(BANKSLIPID, data);
    },
    keepNsuIndex({ commit, state }, data) {
      commit(NSUINDEX, data);
    },
    keepNsu({ commit, state }, data) {
      commit(NSU, data);
    },
    keepCardFinal({ commit, state }, data) {
      commit(CARDFINAL, data);
    },
    keepNoteIndex({ commit, state }, data) {
      commit(NOTEINDEX, data);
    },
    keepNote({ commit, state }, data) {
      commit(NOTE, data);
    },
    init(context) {
      context.commit(INIT);
    },
  },
  mutations: {
    [SHOWINPUTNAME](state, showInputName) {
      state.showInputName = showInputName;
    },
    [SHOWINPUTCPF](state, showInputCPF) {
      state.showInputCPF = showInputCPF;
    },
    [BANKSLIP](state, bankSlip) {
      state.bankSlip = bankSlip;
    },
    [CREDIT](state, credit) {
      state.credit = credit;
    },
    [DEBIT](state, debit) {
      state.debit = debit;
    },
    [PAID](state, paid) {
      state.paid = paid;
    },
    [DELAYED](state, delayed) {
      state.delayed = delayed;
    },
    [UNAUTHORIZED](state, unauthorized) {
      state.unauthorized = unauthorized;
    },
    [PENDING](state, pending) {
      state.pending = pending;
    },
    [CANCELED](state, canceled) {
      state.canceled = canceled;
    },
    [CONTESTED](state, contested) {
      state.contested = contested;
    },
    [LAST7DAYS](state, last7Days) {
      state.last7Days = last7Days;
    },
    [LAST30DAYS](state, last30Days) {
      state.last30Days = last30Days;
    },
    [STARTDATE](state, startDate) {
      state.startDate = startDate;
    },
    [ENDDATE](state, endDate) {
      state.endDate = endDate;
    },
    [CARDBRAND](state, cardBrand) {
      state.cardBrand = cardBrand;
    },
    [SEARCHTYPE](state, searchType) {
      state.searchType = searchType;
    },
    [PAYERTAXDOCUMENT](state, payerTaxDocument) {
      state.payerTaxDocument = payerTaxDocument;
    },
    [PAYERNAMEINDEX](state, payerNameIndex) {
      state.payerNameIndex = payerNameIndex;
    },
    [PAYERNAME](state, payerName) {
      state.payerName = payerName;
    },
    [BANSKSLIPIDINDEX](state, bankslipIdIndex) {
      state.bankslipIdIndex = bankslipIdIndex;
    },
    [BANKSLIPID](state, bankslipId) {
      state.bankslipId = bankslipId;
    },
    [NSUINDEX](state, nsuIndex) {
      state.nsuIndex = nsuIndex;
    },
    [NSU](state, nsu) {
      state.nsu = nsu;
    },
    [CARDFINAL](state, cardFinal) {
      state.cardFinal = cardFinal;
    },
    [NOTEINDEX](state, noteIndex) {
      state.noteIndex = noteIndex;
    },
    [NOTE](state, note) {
      state.note = note;
    },
    [INIT](state) {
      const stringState = localStorage.getItem('state');

      if (stringState) {
        const newState = JSON.parse(stringState);
        state.showInputName = newState.paymentFilters.showInputName;
        state.showInputCPF = newState.paymentFilters.showInputCPF;
        state.bankSlip = newState.paymentFilters.bankSlip;
        state.credit = newState.paymentFilters.credit;
        state.debit = newState.paymentFilters.debit;
        state.paid = newState.paymentFilters.paid;
        state.delayed = newState.paymentFilters.delayed;
        state.unauthorized = newState.paymentFilters.unauthorized;
        state.pending = newState.paymentFilters.pending;
        state.canceled = newState.paymentFilters.canceled;
        state.contested = newState.paymentFilters.contested;
        state.last7Days = newState.paymentFilters.last7Days;
        state.last30Days = newState.paymentFilters.last30Days;
        state.startDate = newState.paymentFilters.startDate;
        state.endDate = newState.paymentFilters.endDate;
        state.cardBrand = newState.paymentFilters.cardBrand;
        state.searchType = newState.paymentFilters.searchType;
        state.payerTaxDocument = newState.paymentFilters.payerTaxDocument;
        state.payerNameIndex = newState.paymentFilters.payerNameIndex;
        state.payerName = newState.paymentFilters.payerName;
        state.bankslipIdIndex = newState.paymentFilters.bankslipIdIndex;
        state.bankslipId = newState.paymentFilters.bankslipId;
        state.nsuIndex = newState.paymentFilters.nsuIndex;
        state.nsu = newState.paymentFilters.nsu;
        state.cardFinal = newState.paymentFilters.cardFinal;
        state.noteIndex = newState.paymentFilters.noteIndex;
        state.note = newState.paymentFilters.note;
      }
    },
  },
  getters: {
    showInputName(state) {
      if (state.showInputName === null) return;
      return state.showInputName;
    },
    showInputCPF(state) {
      if (state.showInputCPF === null) return;
      return state.showInputCPF;
    },
    bankSlip(state) {
      if (state.bankSlip === null) return;
      return state.bankSlip;
    },
    credit(state) {
      if (state.credit === null) return;
      return state.credit;
    },
    debit(state) {
      if (state.debit === null) return;
      return state.debit;
    },
    paid(state) {
      if (state.paid === null) return;
      return state.paid;
    },
    delayed(state) {
      if (state.delayed === null) return;
      return state.delayed;
    },
    unauthorized(state) {
      if (state.unauthorized === null) return;
      return state.unauthorized;
    },
    pending(state) {
      if (state.pending === null) return;
      return state.pending;
    },
    canceled(state) {
      if (state.canceled === null) return;
      return state.canceled;
    },
    contested(state) {
      if (state.contested === null) return;
      return state.contested;
    },
    last7Days(state) {
      if (state.last7Days === null) return;
      return state.last7Days;
    },
    last30Days(state) {
      if (state.last30Days === null) return;
      return state.last30Days;
    },
    startDate(state) {
      if (state.startDate === null) return;
      return state.startDate;
    },
    endDate(state) {
      if (state.endDate === null) return;
      return state.endDate;
    },
    cardBrand(state) {
      if (state.cardBrand === null) return;
      return state.cardBrand;
    },
    searchType(state) {
      if (state.searchType === null) return;
      return state.searchType;
    },
    payerTaxDocument(state) {
      if (state.payerTaxDocument === null) return;
      return state.payerTaxDocument;
    },
    payerNameIndex(state) {
      if (state.payerNameIndex === null) return;
      return state.payerNameIndex;
    },
    payerName(state) {
      if (state.payerName === null) return;
      return state.payerName;
    },
    bankslipIdIndex(state) {
      if (state.bankslipIdIndex === null) return;
      return state.bankslipIdIndex;
    },
    bankslipId(state) {
      if (state.bankslipId === null) return;
      return state.bankslipId;
    },
    nsuIndex(state) {
      if (state.nsuIndex === null) return;
      return state.nsuIndex;
    },
    nsu(state) {
      if (state.nsu === null) return;
      return state.nsu;
    },
    cardFinal(state) {
      if (state.cardFinal === null) return;
      return state.cardFinal;
    },
    noteIndex(state) {
      if (state.noteIndex === null) return;
      return state.noteIndex;
    },
    note(state) {
      if (state.note === null) return;
      return state.note;
    },
  },
};

export default modulePaymentFilters;
