export default class Alert {
  constructor() {
    this.title = '';
    this.message = '';
    this.question = '';
    this.error = '';
    this.isImportant = false;
  }
}

export class PreviewLogoAlert {
  image = ''
  name = ''
  taxDocument = ''
  address = ''
  district = ''
  city = ''
  uf = ''
  phone = ''
}
