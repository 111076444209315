import router from '@/router';
import store from '@/store';
import swal from 'sweetalert2';
import Vue from 'vue';
const EventBus = new Vue();

export const UNAUTHORIZED_RESPONSE_INTERCEPTOR = [
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch('account/logout');
      router.push('/acessar');
      return Promise.resolve(error.response);
    } else if (error.response.status === 403) {
      swal('Sem permissão', 'Você não tem acesso a este recurso.', 'warning');
      router.push('/');
      return Promise.resolve(error.response);
    } else if (error.response.status === 500) {
      EventBus.$bus.$emit('spinner-stop');
      swal({
        title: '<strong>Ops, erro!</strong>',
        type: 'error',
        html:
          'Por favor, entre em contato com o nosso suporte<br>',
        confirmButtonText:
          'Sair',
      });
      return Promise.resolve(error.response);
    } else if (error.response.status === 422) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error.response);
    }
  },
];

export const REQUEST_TOKEN_INTERCEPTOR = [
  config => {
    config.headers['Authorization'] = 'bearer ' + store.getters['account/token'];
    return config;
  },
  Promise.reject,
];
