import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
import { escapeHtml } from './escapeHtml';
import { cnpj } from './cnpj';
import { cpf } from './cpf';
import { abbreviateName } from './abbreviateName';
import { telephone } from './telephone';
import { dateAndTime } from './dateAndTime';
import VueTheMask from 'vue-the-mask';
import money from 'v-money';

Vue.filter('escapeHtml', escapeHtml);
Vue.filter('cnpj', cnpj);
Vue.filter('cpf', cpf);
Vue.filter('abbreviateName', abbreviateName);
Vue.filter('telephone', telephone);
Vue.filter('dateTime', dateAndTime);
Vue.use(Vue2Filters);
Vue.use(VueTheMask);
Vue.use(money);
