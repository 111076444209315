import AccountApi from '@/services/AccountApi';
import router from '@/router';
import {
  LOGIN,
  LOGOUT,
  INIT,
  WHOAMI,
  KEEPTOKEN,
  LOGWITHTOKEN,
  PERMISSIONLIST,
  SET_BANK_ACCOUNT,
} from './mutations-type.js';

const moduleAccount = {
  namespaced: true,
  state: {
    credentials: {
      token: null,
      whoAmI: null,
      permissionList: [],
    },
    bankAccount: null,
    initialize: false,
  },
  actions: {
    Initialize(context) {
      return new Promise((resolve, reject) => {
        if (!context.getters['isInit']) {
          context.commit(INIT);
        }
        resolve();
      });
    },
    async login({ commit, state }, data) {
      commit(LOGIN, { ...data });
    },
    init(context) {
      context.commit(INIT);
    },
    logout(context) {
      context.commit(LOGOUT);
    },
    async askWhoAmI(context) {
      const api = new AccountApi();
      var whoAmI = await api.whoAmI();
      if (whoAmI.error) {
        context.commit(LOGOUT);
        router.push({ path: '/acessar' });
      } else {
        var data = { whoAmI: whoAmI };
        context.commit(WHOAMI, { ...data });
        return whoAmI;
      }
    },
    keepToken({ commit, state }, data) {
      commit(KEEPTOKEN, data);
    },
    logWithToken({ commit, state }, data) {
      commit(LOGWITHTOKEN, data);
    },
    setPermission({ commit, state }, data) {
      commit(PERMISSIONLIST, data);
    },
    async loadBankAccount(context) {
      const accountApi = new AccountApi();
      var presets = await accountApi.presets();
      context.commit(SET_BANK_ACCOUNT, presets.bankAccount);
    },
  },
  mutations: {
    [LOGIN](state, { token }) {
      state.credentials = {
        token,
        whoAmI: null,
        permissionList: [],
      };
    },
    [LOGOUT](state) {
      state.credentials = null;
      localStorage.removeItem('fingerPrint');
    },
    [INIT](state) {
      const stringState = localStorage.getItem('state');
      if (stringState) {
        const newState = JSON.parse(stringState);
        state.initialize = true;
        state.credentials = newState.account.credentials;
      }
    },
    [WHOAMI](state, { whoAmI }) {
      state.credentials.whoAmI = whoAmI;
    },
    [KEEPTOKEN](state, token) {
      state.credentials.token = token;
    },
    [LOGWITHTOKEN](state, token) {
      state.credentials = {
        token: token,
        whoAmI: null,
        permissionList: [],
      };
    },
    [PERMISSIONLIST](state, { permissions }) {
      state.credentials.permissionList = permissions;
    },
    [SET_BANK_ACCOUNT](state, bankAccount) {
      state.bankAccount = bankAccount;
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.credentials !== null && state.credentials.token !== null;
    },
    isInit(state) {
      return state.initialize;
    },
    token(state, getters) {
      if (getters.isAuthenticated) {
        return state.credentials.token;
      } else {
        return '';
      }
    },
    whoAmI(state) {
      if (state.credentials === null) return;
      return state.credentials.whoAmI;
    },
    permissions(state) {
      if (state.credentials === null) return;
      return state.credentials.permissionList;
    },
    bankAccount(state) {
      return state.bankAccount;
    },
  },
};

export default moduleAccount;
