const abbreviateName = function(name, maxLength) {
  var nameArray;
  var abbreviatedName;
  nameArray = name.split(' ');
  if (name.length >= maxLength) {
    if (nameArray[nameArray.length - 1] === '') {
      nameArray.splice(nameArray.length - 1, 1);
    }
    for (var i = 0; i < nameArray.length; i++) {
      if (i === 0) {
        nameArray[i] = `${nameArray[i].charAt(0).toUpperCase()}${nameArray[i]
          .slice(1)
          .toLowerCase()}`;
        abbreviatedName = `${nameArray[i]} `;
      } else if (i === nameArray.length - 1) {
        abbreviatedName = `${abbreviatedName} ${nameArray[i]
          .charAt(0)
          .toUpperCase()}${nameArray[i].slice(1).toLowerCase()}`;
      } else {
        if (
          nameArray[i] === 'de' ||
          nameArray[i] === 'da' ||
          nameArray[i] === 'dos' ||
          nameArray[i] === 'das' ||
          nameArray[i] === 'e'
        ) {
          abbreviatedName = `${abbreviatedName} ${nameArray[i]}`;
        } else {
          if (nameArray[i] !== '') {
            abbreviatedName = `${abbreviatedName} ${nameArray[i]
              .charAt(0)
              .toUpperCase()}. `;
          }
        }
      }
    }
  } else {
    abbreviatedName = name;
  }
  return abbreviatedName;
};

export { abbreviateName };
