export default class CustomApiError {
  constructor(response) {
    if (this.constructor === CustomApiError) {
      throw new Error("Can't instantiate abstract class!");
    }
    this.response = response;
  }

  getMessage() {
    const dictionary = this.toDictionary();
    return dictionary[this.response.error]
      ? dictionary[this.response.error]
      : 'Ocorreu um erro';
  }

  toDictionary() {
    return {};
  }
}
