<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';
import LayoutApi from '@/services/v2/LayoutApi';

export default {
  name: 'RemoteAccess',
  async beforeMount() {
    const api = new LayoutApi();
    const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
    const subdomain = window.location.origin.match(subdomainRegex);

    if (subdomain && subdomain[1] != 'gestao') {
      api.fetch(subdomain[1]).then((response) => {
        window.localStorage.setItem('primaryColor', response.primaryColor);
        window.localStorage.setItem('secondaryColor', response.secondaryColor);
        window.localStorage.setItem('tertiaryColor', response.tertiaryColor);
        window.localStorage.setItem('applicationId', response.applicationId);

        if (response.applicationName) {
          this.setApplicationName(response.applicationName);
          document.title = `${response.applicationName} - NetBanking`;
        } else {
          document.title = 'Pagcerto - NetBanking';
        }

        let link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement('link');
        link.type = 'image/png';
        link.rel = 'icon';
        link.href = response.logo;
        document.getElementsByTagName('head')[0].appendChild(link);
      });
    }
  },
  async mounted() {
    await this.signinAs();
  },
  methods: {
    async signinAs() {
      const match = /.*\/acesso-remoto\?token=(.*)/.exec(window.location.href);
      this.$bus.$emit('spinner-run');
      await this.auth({ token: match[1] });
      await this.askWhoAmI();
      this.$router.push('/');
      this.$bus.$emit('spinner-stop');
    },
    ...mapActions({
      auth: 'account/login',
      askWhoAmI: 'account/askWhoAmI',
      setApplicationName: 'layout/setApplicationName',
    }),
  },
};
</script>
