import Vue from 'vue';

Vue.directive('cpfCnpj', {
  componentUpdated: function(el, binding) {
    /* eslint-disable no-undef */
    $(function() {
      var id = '#' + el.id;
      /* eslint-disable no-undef */
      $(id).unmask();
      var tamanho = $(id)[0].value.length;
      if (tamanho < 11) {
        /* eslint-disable no-undef */
        $(id).mask('999.999.999-99');
      } else if (tamanho >= 11) {
        /* eslint-disable no-undef */
        $(id).mask('99.999.999/9999-99');
      }
      var elem = el;
      setTimeout(function() {
        elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      var currentValue = binding.value;
      /* eslint-disable no-undef */
      $(id).value = '';
      $(id).value = currentValue;
    });
  },
});

export function maskCpfCnpj(id) {
  /* eslint-disable no-undef */
  $(id).keydown(function() {
    try {
      /* eslint-disable no-undef */
      $(id).unmask();
    } catch (e) {}

    var tamanho = $(id).val().length;

    if (tamanho < 11) {
      /* eslint-disable no-undef */
      $(id).mask('999.999.999-99');
    } else if (tamanho >= 11) {
      /* eslint-disable no-undef */
      $(id).mask('99.999.999/9999-99');
    }
    var elem = this;
    setTimeout(function() {
      elem.selectionStart = elem.selectionEnd = 10000;
    }, 0);
    var currentValue = $(this).val();
    /* eslint-disable no-undef */
    $(this).val('');
    $(this).val(currentValue);
  });
}
