<template>
  <div></div>
</template>

<script>
import swal from 'sweetalert2';

export default {
  name: 'AppAlert',
  mounted() {
    this.$bus.$on('alert-on', (data) => {
      this.confirm(
        data.title,
        data.question,
        data.cancelButtonText,
        data.confirmButtonText
      );
    });
    this.$bus.$on('alert-spc', (data) => {
      this.confirmSpc(
        data.title,
        data.question,
        data.cancelButtonText,
        data.confirmButtonText
      );
    });
    this.$bus.$on('alert-sucess', (data) => {
      this.showSuccess(data.title, data.message);
    });
    this.$bus.$on('alert-error', (data) => {
      this.showError(data.title, data.error, data.confirmButtonText);
    });
    this.$bus.$on('alert-warning', (data) => {
      this.showWarning(data.title, data.message);
    });
    this.$bus.$on('alert-warning-html', (data) => {
      this.attentionHtml(data.title, data.message, data.confirmButtonText);
    });
    this.$bus.$on('alert-preview-logo-natural-person', (data) => {
      this.previewLogoNaturalPersonHtml(
        data.image,
        data.name,
        data.address,
        data.district,
        data.city,
        data.uf,
        data.phone
      );
    });
    this.$bus.$on('alert-preview-logo-juristic-person', (data) => {
      this.previewLogoJuristicPersonHtml(
        data.image,
        data.name,
        data.address,
        data.district,
        data.city,
        data.uf,
        data.phone,
        data.taxDocument
      );
    });
  },
  methods: {
    confirm(
      title,
      question,
      cancelButtonText = 'Não',
      confirmButtonText = 'Sim'
    ) {
      swal({
        title: title,
        text: question,
        type: 'warning',
        confirmButtonText:
          confirmButtonText === undefined || confirmButtonText === ''
            ? 'Sim'
            : confirmButtonText,
        cancelButtonText:
          cancelButtonText === undefined || cancelButtonText === ''
            ? 'Não'
            : cancelButtonText,
        showCancelButton: true,
        focusConfirm: false,
        focusCancel: true,
        allowOutsideClick: false,
        reverseButtons: true,
      }).then((result) => this.$bus.$emit('alert-response', result));
    },
    confirmSpc(
      title,
      question,
      cancelButtonText = 'Sim',
      confirmButtonText = 'Nâo'
    ) {
      swal({
        title: title,
        text: question,
        type: 'warning',
        confirmButtonText:
          confirmButtonText === undefined || confirmButtonText === ''
            ? 'Não'
            : confirmButtonText,
        cancelButtonText:
          cancelButtonText === undefined || cancelButtonText === ''
            ? 'Sim'
            : cancelButtonText,
        showCancelButton: true,
        focusConfirm: true,
        focusCancel: false,
        allowOutsideClick: true,
        reverseButtons: true,
      }).then((result) => this.$bus.$emit('alert-response', result));
    },
    showError(title, error, confirmButtonText = 'Ok') {
      swal({
        title: title,
        text: error,
        type: 'error',
        allowOutsideClick: false,
        confirmButtonText: confirmButtonText,
        onClose: () => swal.close(),
      }).then(() => this.$bus.$emit('alert-response'));
    },
    showSuccess(title, message) {
      swal({
        title: title,
        text: message,
        type: 'success',
        allowOutsideClick: false,
      }).then(() => this.$bus.$emit('alert-response'));
    },
    showWarning(title, message) {
      swal({
        title: title,
        text: message,
        type: 'warning',
        allowOutsideClick: false,
      });
    },
    attentionHtml(title, message, confirmButtonText) {
      swal({
        title: title,
        type: 'warning',
        html: message,
        confirmButtonText:
          confirmButtonText === undefined || confirmButtonText === ''
            ? 'OK'
            : confirmButtonText,
      }).then(() => this.$bus.$emit('alert-response'));
    },
    previewLogoNaturalPersonHtml(
      image,
      name,
      address,
      district,
      city,
      uf,
      phone
    ) {
      swal({
        html: `
        <table style="background: #fff;border: 1px solid #e8e8e8;font-family: Arial, Helvetica, sans-serif;font-size: 12pt;line-height: 22px;margin-left: auto;margin-right: auto;width: 640px;">
          <tbody>
            <tr>
              <td style="border-bottom: 1px solid #e8e8e8;color: #8c8c8c;padding: 20px 30px;width: 50%; text-align:left;">
                ${
  image !== ''
    ? `<img src="${image}" class="logo-preview"/>`
    : name
}
                </td>
                <td style="border-bottom: 1px solid #e8e8e8;color: #8c8c8c;padding: 20px 30px;font-size: 10pt; line-height: 18px;text-align: right;">
                    ${name} <br>
                    ${address} <br>
                    ${district} - ${city}/${uf} <br>
                    Fone: ${phone}
                </td>
            </tr>
            <tr>
              <td colspan="2" style="color: #8c8c8c;padding: 20px 30px;text-align:left;">
                <h1 style="color: #f99552;font-size: 18pt;margin-bottom: 30px;">
                  Olá, Maria, tudo bem?
                </h1>
                <p>
                    Queremos te lembrar que há um boleto que vence hoje.
                    Aqui está um resumo da cobrança para você:
                </p>
                <p>
                    Emitido por: <b>${name}</b>
                </p>
                <p>
                    No valor de: <b>R$ 89,90</b>
                </p>
                <p>
                    Com vencimento em: <b>20/12/2018</b>
                </p>
                <p>
                    Descrição do pagamento: <b>Não informado</b>
                </p>
                <p>
                    Pague hoje mesmo e aproveite desconto no boleto.
                </p>
                <p>
                    Para facilitar, aqui estão algumas opções para você caso ainda não tenha realizado o pagamento:
                </p>
                <div style="border: 1px solid #f99552;border-radius: 20px;display: inline-block;padding: 20px 30px;margin: 20px 0;text-align: center;">
                    <p>
                        Prefere copiar ou digitar os números do código de barras?
                        Aqui estão os dados da linha digitável do seu boleto.
                    </p>
                    <p style="color: #333333;">
                        12345.12345 12345.123456 13245.12345 1 12345678901234
                    </p>
                </div>
                <p>
                    Se tiver dúvidas, entre em contato conosco, ok?
                </p>
                <p>
                    Um abraço!<br>
                    <b>${name}</b>
                </p>
                </td>
              </tr>
          </tbody>
        </table>
        `,
        showCloseButton: true,
        customClass: 'swal-wide',
        showConfirmButton: false,
      }).then(() => this.$bus.$emit('alert-response'));
    },
    previewLogoJuristicPersonHtml(
      image,
      name,
      address,
      district,
      city,
      uf,
      phone,
      taxDocument
    ) {
      swal({
        html: `
        <table style="background: #fff;border: 1px solid #e8e8e8;font-family: Arial, Helvetica, sans-serif;font-size: 12pt;line-height: 22px;margin-left: auto;margin-right: auto;width: 640px;">
          <tbody>
            <tr>
              <td style="border-bottom: 1px solid #e8e8e8;color: #8c8c8c;padding: 20px 30px;width: 50%; text-align:left;">
                ${
  image !== ''
    ? `<img src="${image}" class="logo-preview"/>`
    : name
}
              </td>
              <td style="border-bottom: 1px solid #e8e8e8;color: #8c8c8c;padding: 20px 30px;font-size: 10pt; line-height: 18px;text-align: right;">
                  ${name} <br>
                  ${taxDocument} <br>
                  ${address} <br>
                  ${district} - ${city}/${uf} <br>
                  Fone: ${phone}
              </td>
            </tr>
            <tr>
              <td colspan="2" style="color: #8c8c8c;padding: 20px 30px;text-align:left;">
                <h1 style="color: #f99552;font-size: 18pt;margin-bottom: 30px;">
                    Olá, Maria, tudo bem?
                </h1>
                <p>
                  Queremos te lembrar que há um boleto que vence hoje.
                  Aqui está um resumo da cobrança para você:
                </p>
                <p>
                  Emitido por: <b>${name}</b>
                </p>
                <p>
                  No valor de: <b>R$ 89,90</b>
                </p>
                <p>
                  Com vencimento em: <b>20/12/2018</b>
                </p>
                <p>
                  Descrição do pagamento: <b>Não informado</b>
                </p>
                <p>
                  Pague hoje mesmo e aproveite desconto no boleto.
                </p>
                <p>
                  Para facilitar, aqui estão algumas opções para você caso ainda não tenha realizado o pagamento:
                </p>
                <div style="border: 1px solid #f99552;border-radius: 20px;display: inline-block;padding: 20px 30px;margin: 20px 0;text-align: center;">
                  <p>
                    Prefere copiar ou digitar os números do código de barras?
                    Aqui estão os dados da linha digitável do seu boleto.
                  </p>
                  <p style="color: #333333;">
                    12345.12345 12345.123456 13245.12345 1 12345678901234
                  </p>
                </div>
                <p>
                  Se tiver dúvidas, entre em contato conosco, ok?
                </p>
                <p>
                  Um abraço!<br>
                  <b>Equipe</b> .<b>${name}</b>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        `,
        showCloseButton: true,
        customClass: 'swal-wide',
        showConfirmButton: false,
      }).then(() => this.$bus.$emit('alert-response'));
    },
  },
};
</script>
