<template>
  <div>
    <app-spinner />
    <div v-if="canSendDocuments">
      <div class="activation-header">
        <svg
          id="Logo"
          class
          xmlns="http://www.w3.org/2000/svg"
          xml:space="preserve"
          viewBox="0 0 249.5 45"
          version="1.0"
          y="0px"
          x="0px"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          enable-background="new 0 0 249.5 45"
        >
          <g fill="#ffffff" clip-rule="evenodd" fill-rule="evenodd">
            <path
              d="m55.5 8.4l-15.5 9.7v9.2l22.8-14.3-7.3-4.6zm-24.8 15.4l-20.9 13c-0.4 0.3-0.9 0.3-1.4 0-0.4-0.2-0.7-0.7-0.7-1.2v-12.6l-7.7-4.8v17.5c0 3.4 1.8 6.4 4.7 8 1.4 0.8 2.9 1.2 4.5 1.2 1.7 0 3.4-0.5 4.8-1.4l24.5-15.3-7.8-4.4z"
            ></path>
            <path
              d="m34.8 1c-2.9-1.6-6.6-1.3-9.9 0.8-5.6 3.5-22.6 14.1-22.6 14.1l-2.3 1.4 7.8 4.8 21.6-13.5c0.4-0.3 0.9-0.3 1.3 0 0.4 0.2 0.7 0.7 0.7 1.2v13.6l7.8 4.3v-17.9c0-4-1.6-7.2-4.4-8.8z"
            ></path>
            <path
              d="m82.1 24.8c-0.6 5.7-2.2 7.7-6.2 7.7-1.5 0-2.6-0.4-3.3-1.2-1-1.1-1.3-3.1-0.9-6.4 0.7-5.6 2.3-7.7 6.1-7.7 1.6 0 2.7 0.4 3.4 1.2 1 1.2 1.3 3.1 0.9 6.4zm-3.3-11.5c-3.2 0-4.8 1-6 2.2l0.2-1.6h-4.6l-3.4 30.7h4.6l1.1-10.1c0.8 1.1 2 2 4.6 2.2h0.5 0.2 0.1 0.1 0.2c6.2 0 9.7-3.8 10.5-11.4v-0.2-0.4c0.5-4.2-0.1-7.2-1.8-9.2-1.5-1.5-3.6-2.2-6.3-2.2z"
            ></path>
            <path
              d="m103.6 27.6c-0.4 3.5-2.5 5.1-6.7 5.1-1.5 0-2.5-0.2-2.9-0.7-0.4-0.4-0.5-1-0.4-1.9 0.2-2.1 1.3-2.9 4.6-3.3 0 0 2.8-0.3 5.5-0.4l-0.1 1.2zm-2.7-14.3c-6.4 0-9.2 2.1-10.2 7.3l-0.1 0.6h4.6l0.1-0.3c0.7-2.3 1.1-3.5 5.2-3.5 1.9 0 3.1 0.3 3.6 0.9 0.4 0.5 0.5 1.2 0.4 2.4l-0.2 2.2c-1.7-0.1-5.6-0.3-9.2 0.6-4.8 1.2-5.9 4.9-6.1 7-0.2 1.9 0.2 3.4 1.1 4.4 1.1 1.3 3 1.9 5.7 1.9 3.4 0 5.5-1 7.2-2.2l-0.2 1.7h4.6l1.8-16.4c0.2-1.9-0.2-3.4-1.1-4.4-1.4-1.6-3.6-2.2-7.2-2.2z"
            ></path>
            <path
              d="m125.9 18.5c1 1.1 1.2 3 0.8 6.4l-0.7 6.3c-2.3 1.1-4.6 1.2-5.3 1.2-1.9 0-3.1-0.4-3.8-1.1-0.9-1-1.1-2.9-0.8-6.3 0.5-4.9 1.7-7.6 6.2-7.6 1.8 0 2.9 0.3 3.6 1.1zm1.9-3c-1.1-1.3-2.6-2.3-5.7-2.3-6.2 0-9.7 3.8-10.5 11.3-0.5 4.4 0.2 7.6 2 9.6 1.5 1.6 3.6 2.4 6.4 2.4 0.3 0 3.1 0 5.5-1-0.5 3.4-1.3 5.2-6.4 5.2-2.6 0-3.7-0.4-3.8-2.9v-0.5h-4.5v0.5c0 2.2 0.5 3.8 1.6 5 1.3 1.3 3.4 2 6.6 2 9.4 0 10.7-5.4 11.4-11.4l2.2-19.8h-4.6l-0.2 1.9z"
            ></path>
            <path
              d="m149.7 24.8l-0.7 6.3c-2.3 1.1-4.6 1.2-5.3 1.2-1.9 0-3.1-0.4-3.8-1.1-0.9-1-1.1-2.9-0.8-6.3 0.5-4.9 1.7-7.6 6.2-7.6 1.7 0 2.8 0.4 3.5 1.1 1.1 1.1 1.3 3.1 0.9 6.4zm1.3-11l-0.2 1.7c-1.1-1.3-2.6-2.3-5.7-2.3-6.2 0-9.7 3.8-10.5 11.3-0.5 4.4 0.2 7.6 2 9.6 1.5 1.6 3.6 2.4 6.4 2.4 0.3 0 3.1 0 5.5-1-0.5 3.4-1.3 5.2-6.4 5.2-2.6 0-3.7-0.4-3.8-2.9v-0.5h-4.5v0.5c0 2.2 0.5 3.8 1.6 5 1.3 1.3 3.4 2 6.6 2 9.4 0 10.7-5.4 11.4-11.4l2.2-19.8h-4.6z"
            ></path>
            <path
              d="m168.7 13.3c-4.7 0-10.2 1.3-11.3 11.8-0.5 4.5 0 7.5 1.7 9.3 1.4 1.6 3.5 2.3 6.6 2.3 3.9 0 8.6-1 10.3-8.5l0.1-0.6h-4.6l-0.1 0.4c-1 3.9-2.4 4.6-5.1 4.6-1.8 0-2.9-0.3-3.5-1.1-0.9-1-1.1-2.8-0.7-6.3 0.7-6.2 2-7.9 6.3-7.9 2.7 0 3.7 0.6 3.8 3.9v0.5h4.6v-0.5c0-2.8-0.6-4.7-1.9-6s-3.4-1.9-6.2-1.9z"
            ></path>
            <path
              d="m184.3 22.6c1-4.4 2.9-5.2 6.2-5.2 1.7 0 2.9 0.4 3.6 1.1 0.7 0.8 1 2 1 4.1h-10.8zm6.7-9.3c-7 0-10.9 3.9-11.8 12-0.5 4.1 0.1 7 1.8 8.9 1.5 1.7 3.9 2.5 7.4 2.5 6 0 9.6-2.5 10.7-7.5l0.1-0.6h-4.6l-0.1 0.4c-0.6 2.7-2.1 3.6-5.4 3.6-2.1 0-3.4-0.4-4.2-1.2-0.8-0.9-1.1-2.4-1-4.8h15.6v-0.4c0.6-5.2 0-8.5-1.8-10.5-1.4-1.7-3.6-2.4-6.7-2.4z"
            ></path>
            <path
              d="m213.5 13.3c-2.2 0-3.7 0.6-5.1 2l0.2-1.5h-4.3l-2.5 22.3h4.6l1.4-12.4c0.4-4 1.9-5.7 5-5.7 1.1 0 1.8 0.2 1.8 0.2l0.6 0.2 0.5-4.8-0.4-0.1c-0.1 0.1-0.7-0.2-1.8-0.2z"
            ></path>
            <path
              d="m224 8.1h-4.6l-2.6 23.1c-0.2 2.2 0 3.4 0.7 4.2 0.7 0.7 1.7 1.1 3.5 1.1 1.6 0 3.9-0.4 4-0.4l0.4-0.1 0.4-4-0.6 0.1c-0.8 0.1-2 0.2-2.3 0.2-0.4 0-1.2 0-1.4-0.2-0.1-0.1-0.1-0.3-0.1-0.8l1.5-13.5h4.5l0.4-4h-4.5l0.7-5.7z"
            ></path>
            <path
              d="m244.7 24.9c-0.6 5.5-2.5 7.7-6.6 7.7-1.8 0-3-0.4-3.8-1.3-1-1.1-1.3-3.1-1-6.2 0.6-5.5 2.5-7.7 6.6-7.7 1.8 0 3 0.4 3.8 1.3 1 1.1 1.3 3.1 1 6.2zm4.8-2v-0.5c0-2.8-0.7-5-2.1-6.5-1.6-1.8-4-2.6-7.3-2.6-6.9 0-10.8 3.9-11.6 11.7-0.5 4.2 0.2 7.1 1.9 9.1 1.5 1.7 4 2.6 7.3 2.6 6.9 0 10.9-3.9 11.7-11.6 0.1-0.8 0.1-1.5 0.1-2.2z"
            ></path>
          </g>
        </svg>
      </div>
      <div class="mb-5" v-if="step === 0">
        <div class="header-box mt-0 mb-4 py-4">
          <h3 class="text-center mb-0 font-weight-light">
            Oi {{ firstName }}!
          </h3>
        </div>
        <div class="row mx-0 justify-content-center mb-2">
          <div class="col-sm-10 col-md-6 col-lg-4">
            <h4 class="text-center mb-4 font-weight-light px-4">
              Chegou a hora de enviar seus documentos para que possamos analisar
              o seu cadastro.
            </h4>
            <p class="text-center mb-4 px-3" v-if="!hasCompany">
              <strong>Vai ser rapidinho!</strong> Você só vai precisar de um
              documento oficial com sua foto. Pode ser seu RG ou sua CNH, por
              exemplo.
            </p>
            <p class="text-center mb-4 px-3" v-if="hasCompany">
              <strong>Vai ser rapidinho!</strong> Você só vai precisar de um
              documento oficial com uma foto do titular da empresa. Pode ser o
              RG ou sua CNH dele, por exemplo.
            </p>
          </div>
        </div>
        <div class="text-center mt-4">
          <div class="form-group mb-2">
            <button
              class="btn btn-md btn-orange mx-auto"
              @click.prevent="forwardStep"
            >
              Começar
            </button>
          </div>
          <div class="text-center mx-0">
            <router-link
              href="#"
              class="ml-auto mr-3 mb-2"
              :to="{ name: 'activation-start' }"
            >
              <i class="icon-arrow-left-circle mr-1"></i>Voltar para NetBanking
            </router-link>
          </div>
        </div>
      </div>
      <div class="pt-3 mb-5" v-else-if="step === 1">
        <div class="row mx-0">
          <a
            href="#"
            class="ml-auto text-left mr-3 mb-2"
            @click.prevent="backStep"
          >
            <i class="icon-arrow-left-circle mr-1"></i>Passo anterior
          </a>
        </div>
        <div class="row mx-0 justify-content-center mb-3">
          <div class="col-sm-10 col-md-6 col-lg-4">
            <h4
              class="text-center mb-4 font-weight-light px-4"
              v-if="!hasCompany"
            >
              Pra começar, faça o upload de uma foto do seu RG ou CNH.
            </h4>
            <h4
              class="text-center mb-4 font-weight-light px-4"
              v-if="hasCompany"
            >
              Pra começar, faça o upload de uma foto do RG ou CNH do titular.
            </h4>
            <p class="text-center mb-4 px-3">
              Certifique-se de que ele esteja em bom estado e que todas as
              informações estejam legíveis
            </p>
          </div>
        </div>
        <div class="text-center">
          <div class="form-group mb-3">
            <button
              class="btn btn-md btn-orange mx-auto"
              @click.prevent="uploadRG"
            >
              Enviar fotos do RG
            </button>
          </div>
          <div class="form-group">
            <button
              class="btn btn-md btn-outline-orange mx-auto"
              @click.prevent="uploadDocument"
            >
              Enviar foto da CNH
            </button>
          </div>
        </div>
      </div>
      <div class="pt-3 mb5" v-else-if="step === 2">
        <div class="row mx-0">
          <a
            href="#"
            class="ml-auto text-left mr-3 mb-2"
            @click.prevent="backStep"
          >
            <i class="icon-arrow-left-circle mr-1"></i>Passo anterior
          </a>
        </div>
        <div class="row mx-0 justify-content-center mb-3">
          <div class="col-sm-10 col-md-6 col-lg-4">
            <h4
              class="text-center font-weight-light mb-0"
              v-if="!documentFront.display"
            >
              {{ isAndroid ? "Tire foto " : "Faça o upload" }}
              {{ isRG ? "da frente do RG." : "do documento." }}
            </h4>
            <h4
              class="text-center font-weight-light mb-0"
              v-if="documentFront.display && isAndroid && !hasCompany"
            >
              Confira sua foto
            </h4>
            <h4
              class="text-center font-weight-light mb-0"
              v-if="documentFront.display && isAndroid && hasCompany"
            >
              Confira a foto
            </h4>
          </div>
        </div>
        <div class="text-center">
          <div class="form-group mb-2" v-if="!isAndroid">
            <label class="btn btn-orange">
              {{
                documentFront.display === ""
                  ? "Escolher arquivo"
                  : "Escolher novamente"
              }}
              <input
                type="file"
                id="file"
                accept="image/*"
                ref="front"
                @change="frontUpload()"
              />
            </label>
            {{ documentFront.image.name }}
          </div>
          <div class="form-group" v-if="isAndroid && mediaStream !== null">
            <div v-if="documentFront.display === ''">
              <div class="form-group mb-2">
                <video ref="video" class="camera-stream camera-size rounded" />
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-orange"
                  @click.prevent="capture(documentFront)"
                >
                  <i class="icon-camera"></i> Tirar foto
                </button>
              </div>
            </div>
          </div>
          <div
            class="text-center mb-5"
            v-if="isAndroid && mediaStream === null"
          >
            <p class="text-center mb-4 px-3 text-danger">
              Não conseguimos acessar a câmera do seu dispositivo. <br />Para
              liberar o acesso, recarregue a página e clique no botão
              <br />"Permitir" assim que a mensagem de solicitação aparecer na
              tela.
            </p>
          </div>
          <div v-if="documentFront.display !== ''">
            <div class="form-group mb-2">
              <img :src="documentFront.display" class="rounded camera-size" />
            </div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-outline-orange"
                @click="resetPicture(documentFront)"
                v-if="isAndroid"
              >
                Tirar outra foto
              </button>
              <button
                class="btn btn-md btn-orange mr-auto"
                @click.prevent="forwardStep"
              >
                Próximo passo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-3 mb-5" v-else-if="step === 3">
        <div class="row mx-0">
          <a
            href="#"
            class="ml-auto text-left mr-3 mb-2"
            @click.prevent="backStep"
          >
            <i class="icon-arrow-left-circle mr-1"></i>Passo anterior
          </a>
        </div>
        <div class="row mx-0 justify-content-center mb-3">
          <div class="col-sm-10 col-md-6 col-lg-4">
            <h4
              class="text-center font-weight-light mb-0"
              v-if="!documentBack.display"
            >
              {{
                isAndroid
                  ? "Agora uma foto do verso do RG."
                  : "Agora faça o upload do verso do RG."
              }}
            </h4>
            <h4
              class="text-center font-weight-light mb-0"
              v-if="documentBack.display && isAndroid"
            >
              Confira a foto
            </h4>
          </div>
        </div>
        <div class="text-center mb-5">
          <div class="form-group mb-2" v-if="!isAndroid">
            <label class="btn btn-orange">
              {{
                documentBack.display === ""
                  ? "Escolher arquivo"
                  : "Escolher novamente"
              }}
              <input
                type="file"
                id="fileBack"
                accept="image/*"
                ref="back"
                @change="backUpload()"
              />
            </label>
            {{ documentBack.image.name }}
          </div>
          <div class="form-group mb-2" v-if="isAndroid && mediaStream !== null">
            <div v-if="documentBack.display === ''">
              <div class="form-group mb-2">
                <video ref="video" class="camera-stream rounded camera-size" />
              </div>
              <div class="form-group mb-2">
                <button
                  type="button"
                  class="btn btn-orange"
                  @click.prevent="capture(documentBack)"
                >
                  <i class="icon-camera"></i> Tirar foto
                </button>
              </div>
            </div>
          </div>
          <div
            class="text-center mb-5"
            v-if="isAndroid && mediaStream === null"
          >
            <p class="text-center mb-4 px-3 text-danger">
              Não conseguimos acessar a câmera do seu dispositivo. <br />Para
              liberar o acesso, recarregue a página e clique no botão
              <br />"Permitir" assim que a mensagem de solicitação aparecer na
              tela.
            </p>
          </div>
          <div v-if="documentBack.display !== ''">
            <div class="form-group mb-2">
              <img :src="documentBack.display" class="rounded camera-size" />
            </div>
            <div class="form-group mb-2">
              <button
                type="button"
                class="btn btn-outline-orange"
                @click.prevent="resetPicture(documentBack)"
                v-if="isAndroid"
              >
                Tirar outra foto
              </button>
              <button
                class="btn btn-md btn-orange mr-auto"
                @click.prevent="forwardStep"
              >
                Próximo passo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-3 mb-5" v-else-if="step === 4">
        <div class="row mx-0">
          <a
            href="#"
            class="ml-auto text-left mr-3 mb-2"
            @click.prevent="backStep"
          >
            <i class="icon-arrow-left-circle mr-1"></i>Passo anterior
          </a>
        </div>
        <div class="row mx-0 justify-content-center mb-3">
          <div class="col-sm-10 col-md-6 col-lg-4">
            <h4
              class="text-center font-weight-light mb-0"
              v-if="!selfie.display && !hasCompany"
            >
              Boa!! Agora tire uma foto do seu rosto ao lado do documento que
              você acabou de fotografar.
            </h4>
            <h4
              class="text-center font-weight-light mb-0"
              v-if="!selfie.display && hasCompany"
            >
              Boa!! Agora tire uma foto do rosto do titular ao lado do documento
              que você acabou de fotografar.
            </h4>
            <h4
              class="text-center font-weight-light mb-0"
              v-if="selfie.display"
            >
              Confira sua foto
            </h4>
          </div>
        </div>
        <div class="text-center mb-5" v-if="!isIOS && mediaStream !== null">
          <div v-if="selfie.display === ''">
            <div class="form-group mb-2">
              <video
                ref="videoFront"
                class="camera-stream camera-size rounded"
              />
            </div>
            <div class="form-group mb-2">
              <button
                type="button"
                class="btn btn-orange"
                @click.prevent="capture(selfie)"
              >
                <i class="icon-camera"></i> Tirar uma selfie
              </button>
            </div>
          </div>
          <div v-if="selfie.display !== ''">
            <div class="form-group mb-2">
              <img :src="selfie.display" class="rounded camera-size" />
            </div>
            <div class="form-group mb-2">
              <button
                type="button"
                class="btn btn-outline-orange"
                @click.prevent="resetPicture(selfie)"
              >
                Tirar outra selfie
              </button>
              <button
                class="btn btn-md btn-orange mr-auto"
                @click.prevent="sendFiles"
              >
                Próximo passo
              </button>
            </div>
          </div>
        </div>
        <div class="text-center mb-5" v-if="!isIOS && mediaStream === null">
          <p class="text-center mb-4 px-3 text-danger" v-if="isAndroid">
            Não conseguimos acessar a câmera do seu dispositivo. <br />Para
            liberar o acesso, recarregue a página e clique no botão
            <br />"Permitir" assim que a mensagem de solicitação aparecer na
            tela.
          </p>
          <p class="text-center mb-4 text-danger" v-if="!isAndroid">
            Não conseguimos acessar a câmera do seu dispositivo. <br />Para
            liberar o acesso, verifique se existe uma câmera <br />conectada,
            recarregue a página e clique no botão <br />"Permitir" assim que a
            mensagem de solicitação aparecer na tela.
          </p>
        </div>
        <div class="text-center mb-5">
          <div class="form-group mb-2" v-if="isIOS">
            <label class="btn btn-orange">
              {{
                selfie.display === ""
                  ? "Escolher arquivo"
                  : "Escolher novamente"
              }}
              <input
                type="file"
                id="fileSelfie"
                accept="image/*"
                ref="selfie"
                @change="selfieUpload()"
              />
            </label>
            {{ selfie.image.name }}
            <div v-if="selfie.display !== ''">
              <div class="form-group mb-2">
                <img :src="selfie.display" class="rounded camera-size" />
              </div>
              <div class="form-group mb-2">
                <button
                  class="btn btn-md btn-orange mr-auto"
                  @click.prevent="sendFiles"
                >
                  Próximo passo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5" v-else-if="step >= 5">
        <div class="header-box mt-0 mb-4 py-4">
          <h3 class="text-center font-weight-light mb-0">
            Prontinho {{ firstName }}!
          </h3>
        </div>
        <div class="row mx-0 justify-content-center">
          <div class="col-sm-10 col-md-6 col-lg-4">
            <h4 class="text-center mb-4 font-weight-light">
              Agora é só aguardar a análise das informações.
            </h4>
            <p class="mb-4">
              Vamos passar todos os seus documentos para o setor responsável e
              em breve entraremos em contato com você. O prazo para dar uma
              resposta é de até 2 dias.
            </p>
            <p class="mb-4">
              Essa análise existe para garantir que as informações estão
              corretas e que você não terá nenhum problema.
            </p>
            <p class="mb-4">
              Enquanto isso, aproveite esse tempinho para experimentar a
              NetBanking. :)
            </p>
            <div class="text-center mx-0">
              <router-link
                href="#"
                class="ml-auto mr-3 mb-2"
                :to="{ name: 'activation-start' }"
              >
                <i class="icon-arrow-left-circle mr-1"></i>Voltar para
                NetBanking
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AccountApi from '@/services/AccountApi';
import AppSpinner from '@/components/theme/Spinner';

export default {
  name: 'AppUploadDocuments',
  async mounted() {
    const match = /.*\/ativacao\?token=(.*)/.exec(window.location.href);
    if (match === null) {
      this.$router.push('/');
    }
    this.logWithToken(match[1]);
    await this.askWhoAmI();
    if (this.documentSent || this.approved) {
      this.$router.push('/');
    } else {
      this.canSendDocuments = true;
    }
  },
  data() {
    return {
      step: 0,
      isRG: false,
      canSendDocuments: false,
      documentFront: {
        display: '',
        image: {
          name: 'Faça o upload da frente do documento',
        },
      },
      documentBack: {
        display: '',
        image: {
          name: 'Faça o upload da parte de trás do documento',
        },
      },
      selfie: {
        display: '',
        image: '',
      },
      mediaStream: null,
    };
  },
  methods: {
    ...mapActions({
      logWithToken: 'account/logWithToken',
      askWhoAmI: 'account/askWhoAmI',
    }),
    frontUpload() {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.documentFront.display = e.target.result;
      };
      reader.readAsDataURL(this.$refs.front.files[0]);
      this.documentFront.image = this.$refs.front.files[0];
      document.getElementById('file').value = '';
    },
    backUpload() {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.documentBack.display = e.target.result;
      };
      reader.readAsDataURL(this.$refs.back.files[0]);
      this.documentBack.image = this.$refs.back.files[0];
      document.getElementById('fileBack').value = '';
    },
    selfieUpload() {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selfie.display = e.target.result;
      };
      reader.readAsDataURL(this.$refs.selfie.files[0]);
      this.selfie.image = this.$refs.selfie.files[0];
      document.getElementById('fileSelfie').value = '';
    },
    forwardStep() {
      this.$bus.$emit('spinner-run');
      if (!this.isRG && this.step === 2) {
        this.step++;
      }
      this.step++;
      if (!this.isIOS && this.step === 1) {
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'environment' } })
          .then((mediaStream) => {
            this.mediaStream = mediaStream;
          })
          .catch((error) => console.error('getUserMedia() error:', error));
      } else if (this.isAndroid && (this.step === 2 || this.step === 3)) {
        this.$bus.$emit('spinner-run');
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'environment' } })
          .then((mediaStream) => {
            this.mediaStream = mediaStream;
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
            this.$bus.$emit('spinner-stop');
          })
          .catch((error) => {
            console.error('getUserMedia() error:', error);
            this.mediaStream = null;
            this.$bus.$emit('spinner-stop');
          });
      } else if (this.isAndroid && this.step === 4) {
        this.$bus.$emit('spinner-run');
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'user' } })
          .then((mediaStream) => {
            this.mediaStream = mediaStream;
            this.$refs.videoFront.srcObject = mediaStream;
            this.$refs.videoFront.play();
            this.$bus.$emit('spinner-stop');
          })
          .catch((error) => {
            console.error('getUserMedia() error:', error);
            this.mediaStream = null;
            this.$bus.$emit('spinner-stop');
          });
      } else if (!this.isIOS && this.step === 4) {
        this.$bus.$emit('spinner-run');
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((mediaStream) => {
            this.mediaStream = mediaStream;
            this.$refs.videoFront.srcObject = mediaStream;
            this.$refs.videoFront.play();
            this.$bus.$emit('spinner-stop');
          })
          .catch((error) => {
            console.error('getUserMedia() error:', error);
            this.mediaStream = null;
            this.$bus.$emit('spinner-stop');
          });
      }
      this.$bus.$emit('spinner-stop');
    },
    backStep() {
      if (!this.isRG && this.step === 4) {
        this.step--;
      }
      this.step--;
    },
    uploadRG() {
      this.isRG = true;
      this.forwardStep();
    },
    uploadDocument() {
      this.isRG = false;
      this.forwardStep();
    },
    capture(picture) {
      this.$bus.$emit('spinner-run');
      const reader = new FileReader();
      const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
      const imageCapture = new window.ImageCapture(mediaStreamTrack);
      imageCapture.grabFrame().then((imageBitmap) => {
        const canvas = document.createElement('canvas');
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        canvas.getContext('2d').drawImage(imageBitmap, 0, 0);
        const canvasB64 = canvas.toDataURL('image/png');
        const block = canvasB64.split(';');
        const contentType = block[0].split(':')[1];
        const realData = block[1].split(',')[1];
        picture.image = this.b64toBlob(realData, contentType);
        reader.onload = (e) => {
          picture.display = e.target.result;
        };
        reader.readAsDataURL(picture.image);
        mediaStreamTrack.stop();
        this.$bus.$emit('spinner-stop');
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    resetPicture(picture) {
      picture.display = '';
      picture.image = '';
      if (this.isAndroid && (this.step === 2 || this.step === 3)) {
        this.$bus.$emit('spinner-run');
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'environment' } })
          .then((mediaStream) => {
            this.mediaStream = mediaStream;
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
            this.$bus.$emit('spinner-stop');
          })
          .catch((error) => {
            console.error('getUserMedia() error:', error);
            this.$bus.$emit('spinner-stop');
          });
      } else if (this.isAndroid && this.step === 4) {
        this.$bus.$emit('spinner-run');
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'user' } })
          .then((mediaStream) => {
            this.mediaStream = mediaStream;
            this.$refs.videoFront.srcObject = mediaStream;
            this.$refs.videoFront.play();
            this.$bus.$emit('spinner-stop');
          })
          .catch((error) => {
            console.error('getUserMedia() error:', error);
            this.$bus.$emit('spinner-stop');
          });
      } else if (this.step === 4) {
        this.$bus.$emit('spinner-run');
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((mediaStream) => {
            this.mediaStream = mediaStream;
            this.$refs.videoFront.srcObject = mediaStream;
            this.$refs.videoFront.play();
            this.$bus.$emit('spinner-stop');
          })
          .catch((error) => {
            console.error('getUserMedia() error:', error);
            this.$bus.$emit('spinner-stop');
          });
      }
    },
    async sendFiles() {
      this.$bus.$emit('spinner-run');
      const api = new AccountApi();
      let response = null;
      let selfiePayload = new FormData();
      selfiePayload.append('name', 'image');
      selfiePayload.append('selfie', this.selfie.image);
      response = await api.sendSelfie(selfiePayload);
      let documentFrontPayload = new FormData();
      documentFrontPayload.append('name', 'image');
      documentFrontPayload.append('document', this.documentFront.image);
      response = await api.sendDocumentFront(documentFrontPayload);
      if (this.isRG) {
        let documentBackPayload = new FormData();
        documentBackPayload.append('name', 'image');
        documentBackPayload.append('document', this.documentBack.image);
        response = await api.sendDocumentBack(documentBackPayload);
      }
      this.forwardStep();
      this.$bus.$emit('spinner-stop');
    },
  },
  computed: {
    ...mapGetters({
      token: 'account/token',
      whoAmI: 'account/whoAmI',
    }),
    trialTime() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.freeTrial;
    },
    approved() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.approved;
    },
    firstName() {
      if (!this.whoAmI) return '';
      let name = this.whoAmI.user.fullName.split(' ');
      return name[0];
    },
    isMobile: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);
    },
    isAndroid: function() {
      return navigator.userAgent.match(/Android/i);
    },
    isIOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    hasCompany() {
      if (!this.whoAmI) return '';
      if (!this.whoAmI.holder.company) {
        return false;
      } else {
        return true;
      }
    },
    documentSent() {
      if (!this.whoAmI) return '';
      return this.whoAmI.documentSent;
    },
  },
  components: {
    AppSpinner,
  },
};
</script>
