<template>
  <div class="spinner-wrapper spinner-block" :class="{ show: isReady }">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'AppSpinner',
  data() {
    return {
      running: false,
    };
  },
  mounted() {
    this.$bus.$on('spinner-run', (result) => {
      this.run();
    });
    this.$bus.$on('spinner-stop', (result) => {
      this.stop();
    });
  },
  methods: {
    run() {
      this.running = true;
    },
    stop() {
      this.running = false;
    },
  },
  computed: {
    isReady() {
      return this.running;
    },
  },
};
</script>
