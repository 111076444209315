import { Offset } from './Offset';

export class User {
  id = null
  roleId = ''
  fullName = ''
  email = ''
  taxDocument = ''
  active = true
  isAccountHolder = false
  createdAt = ''
  role = null
}

export class UserFilter {
  fullName = ''
  taxDocument = ''
  email = ''
  offset = new Offset()
}

export class UserParams {
  id = null
  roleId = ''
  fullName = ''
  taxDocument = ''
  email = ''
  active = true
  appUrl = ''

  constructor(user) {
    this.id = user.id;
    this.roleId = user.role.id;
    this.fullName = user.fullName;
    this.taxDocument = user.taxDocument;
    this.email = user.email;
    this.active = user.active;
    this.appUrl = `${location.origin}/criar-nova-senha/`;
  }
}

export class UserList {
  users = [];
  count = 0;
}
