<template>
  <div>
    <div class="activation-header">
      <svg
        id="Logo"
        class
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        viewBox="0 0 249.5 45"
        version="1.0"
        y="0px"
        x="0px"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        enable-background="new 0 0 249.5 45"
      >
        <g fill="#ffffff" clip-rule="evenodd" fill-rule="evenodd">
          <path
            d="m55.5 8.4l-15.5 9.7v9.2l22.8-14.3-7.3-4.6zm-24.8 15.4l-20.9 13c-0.4 0.3-0.9 0.3-1.4 0-0.4-0.2-0.7-0.7-0.7-1.2v-12.6l-7.7-4.8v17.5c0 3.4 1.8 6.4 4.7 8 1.4 0.8 2.9 1.2 4.5 1.2 1.7 0 3.4-0.5 4.8-1.4l24.5-15.3-7.8-4.4z"
          />
          <path
            d="m34.8 1c-2.9-1.6-6.6-1.3-9.9 0.8-5.6 3.5-22.6 14.1-22.6 14.1l-2.3 1.4 7.8 4.8 21.6-13.5c0.4-0.3 0.9-0.3 1.3 0 0.4 0.2 0.7 0.7 0.7 1.2v13.6l7.8 4.3v-17.9c0-4-1.6-7.2-4.4-8.8z"
          />
          <path
            d="m82.1 24.8c-0.6 5.7-2.2 7.7-6.2 7.7-1.5 0-2.6-0.4-3.3-1.2-1-1.1-1.3-3.1-0.9-6.4 0.7-5.6 2.3-7.7 6.1-7.7 1.6 0 2.7 0.4 3.4 1.2 1 1.2 1.3 3.1 0.9 6.4zm-3.3-11.5c-3.2 0-4.8 1-6 2.2l0.2-1.6h-4.6l-3.4 30.7h4.6l1.1-10.1c0.8 1.1 2 2 4.6 2.2h0.5 0.2 0.1 0.1 0.2c6.2 0 9.7-3.8 10.5-11.4v-0.2-0.4c0.5-4.2-0.1-7.2-1.8-9.2-1.5-1.5-3.6-2.2-6.3-2.2z"
          />
          <path
            d="m103.6 27.6c-0.4 3.5-2.5 5.1-6.7 5.1-1.5 0-2.5-0.2-2.9-0.7-0.4-0.4-0.5-1-0.4-1.9 0.2-2.1 1.3-2.9 4.6-3.3 0 0 2.8-0.3 5.5-0.4l-0.1 1.2zm-2.7-14.3c-6.4 0-9.2 2.1-10.2 7.3l-0.1 0.6h4.6l0.1-0.3c0.7-2.3 1.1-3.5 5.2-3.5 1.9 0 3.1 0.3 3.6 0.9 0.4 0.5 0.5 1.2 0.4 2.4l-0.2 2.2c-1.7-0.1-5.6-0.3-9.2 0.6-4.8 1.2-5.9 4.9-6.1 7-0.2 1.9 0.2 3.4 1.1 4.4 1.1 1.3 3 1.9 5.7 1.9 3.4 0 5.5-1 7.2-2.2l-0.2 1.7h4.6l1.8-16.4c0.2-1.9-0.2-3.4-1.1-4.4-1.4-1.6-3.6-2.2-7.2-2.2z"
          />
          <path
            d="m125.9 18.5c1 1.1 1.2 3 0.8 6.4l-0.7 6.3c-2.3 1.1-4.6 1.2-5.3 1.2-1.9 0-3.1-0.4-3.8-1.1-0.9-1-1.1-2.9-0.8-6.3 0.5-4.9 1.7-7.6 6.2-7.6 1.8 0 2.9 0.3 3.6 1.1zm1.9-3c-1.1-1.3-2.6-2.3-5.7-2.3-6.2 0-9.7 3.8-10.5 11.3-0.5 4.4 0.2 7.6 2 9.6 1.5 1.6 3.6 2.4 6.4 2.4 0.3 0 3.1 0 5.5-1-0.5 3.4-1.3 5.2-6.4 5.2-2.6 0-3.7-0.4-3.8-2.9v-0.5h-4.5v0.5c0 2.2 0.5 3.8 1.6 5 1.3 1.3 3.4 2 6.6 2 9.4 0 10.7-5.4 11.4-11.4l2.2-19.8h-4.6l-0.2 1.9z"
          />
          <path
            d="m149.7 24.8l-0.7 6.3c-2.3 1.1-4.6 1.2-5.3 1.2-1.9 0-3.1-0.4-3.8-1.1-0.9-1-1.1-2.9-0.8-6.3 0.5-4.9 1.7-7.6 6.2-7.6 1.7 0 2.8 0.4 3.5 1.1 1.1 1.1 1.3 3.1 0.9 6.4zm1.3-11l-0.2 1.7c-1.1-1.3-2.6-2.3-5.7-2.3-6.2 0-9.7 3.8-10.5 11.3-0.5 4.4 0.2 7.6 2 9.6 1.5 1.6 3.6 2.4 6.4 2.4 0.3 0 3.1 0 5.5-1-0.5 3.4-1.3 5.2-6.4 5.2-2.6 0-3.7-0.4-3.8-2.9v-0.5h-4.5v0.5c0 2.2 0.5 3.8 1.6 5 1.3 1.3 3.4 2 6.6 2 9.4 0 10.7-5.4 11.4-11.4l2.2-19.8h-4.6z"
          />
          <path
            d="m168.7 13.3c-4.7 0-10.2 1.3-11.3 11.8-0.5 4.5 0 7.5 1.7 9.3 1.4 1.6 3.5 2.3 6.6 2.3 3.9 0 8.6-1 10.3-8.5l0.1-0.6h-4.6l-0.1 0.4c-1 3.9-2.4 4.6-5.1 4.6-1.8 0-2.9-0.3-3.5-1.1-0.9-1-1.1-2.8-0.7-6.3 0.7-6.2 2-7.9 6.3-7.9 2.7 0 3.7 0.6 3.8 3.9v0.5h4.6v-0.5c0-2.8-0.6-4.7-1.9-6s-3.4-1.9-6.2-1.9z"
          />
          <path
            d="m184.3 22.6c1-4.4 2.9-5.2 6.2-5.2 1.7 0 2.9 0.4 3.6 1.1 0.7 0.8 1 2 1 4.1h-10.8zm6.7-9.3c-7 0-10.9 3.9-11.8 12-0.5 4.1 0.1 7 1.8 8.9 1.5 1.7 3.9 2.5 7.4 2.5 6 0 9.6-2.5 10.7-7.5l0.1-0.6h-4.6l-0.1 0.4c-0.6 2.7-2.1 3.6-5.4 3.6-2.1 0-3.4-0.4-4.2-1.2-0.8-0.9-1.1-2.4-1-4.8h15.6v-0.4c0.6-5.2 0-8.5-1.8-10.5-1.4-1.7-3.6-2.4-6.7-2.4z"
          />
          <path
            d="m213.5 13.3c-2.2 0-3.7 0.6-5.1 2l0.2-1.5h-4.3l-2.5 22.3h4.6l1.4-12.4c0.4-4 1.9-5.7 5-5.7 1.1 0 1.8 0.2 1.8 0.2l0.6 0.2 0.5-4.8-0.4-0.1c-0.1 0.1-0.7-0.2-1.8-0.2z"
          />
          <path
            d="m224 8.1h-4.6l-2.6 23.1c-0.2 2.2 0 3.4 0.7 4.2 0.7 0.7 1.7 1.1 3.5 1.1 1.6 0 3.9-0.4 4-0.4l0.4-0.1 0.4-4-0.6 0.1c-0.8 0.1-2 0.2-2.3 0.2-0.4 0-1.2 0-1.4-0.2-0.1-0.1-0.1-0.3-0.1-0.8l1.5-13.5h4.5l0.4-4h-4.5l0.7-5.7z"
          />
          <path
            d="m244.7 24.9c-0.6 5.5-2.5 7.7-6.6 7.7-1.8 0-3-0.4-3.8-1.3-1-1.1-1.3-3.1-1-6.2 0.6-5.5 2.5-7.7 6.6-7.7 1.8 0 3 0.4 3.8 1.3 1 1.1 1.3 3.1 1 6.2zm4.8-2v-0.5c0-2.8-0.7-5-2.1-6.5-1.6-1.8-4-2.6-7.3-2.6-6.9 0-10.8 3.9-11.6 11.7-0.5 4.2 0.2 7.1 1.9 9.1 1.5 1.7 4 2.6 7.3 2.6 6.9 0 10.9-3.9 11.7-11.6 0.1-0.8 0.1-1.5 0.1-2.2z"
          />
        </g>
      </svg>
    </div>
    <div class="mt-0 py-5">
      <h3 class="text-center text-danger font-weight-light mb-5 px-2">
        <i class="icon-close mr-3"></i>Link expirado
      </h3>
      <div class="row mx-0 justify-content-center mb-4">
        <div class="col-sm-10 col-md-6 col-lg-4">
          <div class="text-center">
            <p>
              Pode ser que o SMS demore alguns minutos para chegar.
              <br />Caso você não receba tente novamente mais tarde ou entre em
              contato com o nosso atendimento através do número:
            </p>
            <p>
              <strong>(79)3021-0022</strong>
              <br />ou do email:
              <br />
              <strong>atendimento@pagcerto.com.br</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppLinkExpired',
};
</script>
