<template>
  <div>
    <app-alert />
    <app-spinner />
    <div class="container login-container" v-if="readyToRender">
      <WhiteLabel :theme="layout">
        <form
          @submit.prevent="signin"
          autocomplete="off"
          class="d-flex justify-content-around"
        >
          <div class="login-form">
            <div class="pb-5">
              <WhiteLabel>
                <img
                  class="login-img"
                  :src="layout.negativeLogo || layout.logo"
                />
              </WhiteLabel>
              <img
                v-if="!layout.logo && !layout.negativeLogo"
                class="login-img"
                src="https://cdn.pagcerto.com.br/img/logo/logo-branco.png"
              />
            </div>
            <div class="form-group pb-4">
              <div class="input-group">
                <span class="input-group-addon login p-2 px-3">
                  <svg
                    id="User"
                    data-name="User"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 60 60"
                  >
                    <g class="cls-1">
                      <path
                        class="cls-2"
                        d="M56.35,60H3.66a3.45,3.45,0,0,1-2.5-1.09,3.4,3.4,0,0,1-.89-2.54,29.82,29.82,0,0,1,59.47,0,3.39,3.39,0,0,1-.89,2.54A3.44,3.44,0,0,1,56.35,60ZM30,31.62a27,27,0,0,0-26.88,25,.46.46,0,0,0,.13.38.52.52,0,0,0,.4.18H56.35a.52.52,0,0,0,.4-.18.5.5,0,0,0,.14-.38A27,27,0,0,0,30,31.62Z"
                      />
                    </g>
                    <g class="cls-1">
                      <path
                        class="cls-2"
                        d="M30,31.56A15.83,15.83,0,1,1,45.84,15.73,15.85,15.85,0,0,1,30,31.56Zm0-28.8a13,13,0,1,0,13,13A13,13,0,0,0,30,2.76Z"
                      />
                    </g>
                  </svg>
                </span>
                <input
                  type="text"
                  class="form-control input-login"
                  autofocus
                  v-model="username"
                  @input="$v.username.$touch()"
                  placeholder="E-mail cadastrado"
                />
              </div>
              <small class="text-danger" v-if="usernameIsInvalid">
                <b>* E-mail inválido.</b>
              </small>
            </div>
            <div class="form-group pb-4">
              <div class="input-group">
                <span class="input-group-addon login p-2 px-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Password"
                    data-name="Password"
                    viewBox="0 0 60 60"
                  >
                    <path
                      class="cls-1"
                      d="M48.07,60H11.93A4.92,4.92,0,0,1,7,55.09V24.28a4.92,4.92,0,0,1,4.93-4.91H48.07A4.92,4.92,0,0,1,53,24.28V55.09A4.92,4.92,0,0,1,48.07,60ZM11.93,21.22a3.07,3.07,0,0,0-3.07,3.06V55.09a3.06,3.06,0,0,0,3.07,3H48.07a3.06,3.06,0,0,0,3.07-3V24.28a3.07,3.07,0,0,0-3.07-3.06Z"
                    />
                    <path
                      class="cls-1"
                      d="M44,20.79a.93.93,0,0,1-.94-.93V14.51a12.55,12.55,0,0,0-25.09,0v5.35a.93.93,0,0,1-1.86,0V14.51a14.41,14.41,0,0,1,28.82,0v5.35A.93.93,0,0,1,44,20.79Z"
                    />
                    <path
                      class="cls-1"
                      d="M30,41.54a4.7,4.7,0,1,1,4.73-4.7A4.72,4.72,0,0,1,30,41.54Zm0-7.42a2.72,2.72,0,1,0,2.73,2.72A2.73,2.73,0,0,0,30,34.12Z"
                    />
                    <rect
                      class="cls-1"
                      x="28.73"
                      y="39.55"
                      width="2.53"
                      height="9.26"
                    />
                  </svg>
                </span>
                <input
                  class="form-control input-login"
                  id="password"
                  type="password"
                  ref="password"
                  v-model="password"
                  @input="$v.password.$touch()"
                  placeholder="• • • • • • • •"
                />
              </div>
              <small class="text-danger" v-if="passwordIsInvalid">
                <b>* Senha obrigatória.</b>
              </small>
            </div>
            <div class="form-group pb-5">
              <vue-recaptcha
                loadRecaptchaScript
                ref="recaptcha"
                :sitekey="recaptcha.siteKey"
                @verify="onVerify($event)"
                @expired="onExpired"
              >
              </vue-recaptcha>
              <small
                class="text-danger"
                v-if="this.$v.recaptcha.$dirty && !recaptcha.token"
              >
                <b>* reCaptcha é obrigatória.</b>
              </small>
            </div>
            <div class="form-group pb-5">
              <button
                type="submit"
                class="btn btn-block btn-grass text-uppercase"
              >
                Entrar
              </button>
            </div>
            <div
              class="row text-white font-weight-light justify-content-center"
            >
              <div class="col-auto">
                <router-link
                  class="text-white font-weight-light text-10pt"
                  href="#"
                  :to="{ name: 'SendEmailRecovery' }"
                  >Esqueci minha senha</router-link
                >
              </div>
            </div>
          </div>
        </form>
      </WhiteLabel>
    </div>
    <WhiteLabel v-if="readyToRender">
      <div class="footer">
        <small class="text-uppercase">
          <p class="text-center font-weight-light text-white pt-2">
            Copyright &#9400; 2020 Pagcerto. Todos os direitos reservados.
          </p>
        </small>
      </div>
    </WhiteLabel>
  </div>
</template>

<style>
body {
  height: 0 !important;
}
</style>

<script>
/* eslint-disable no-undef */
import AccountApi from '@/services/AccountApi';
import LayoutApi from '@/services/v2/LayoutApi';
import { mapActions, mapGetters } from 'vuex';
import LoginError from '@/errors/LoginError';
import { required } from 'vuelidate/lib/validators';
import Alert from '@/models/Alert';
import AppAlert from '@/components/theme/Alert';
import AppSpinner from '@/components/theme/Spinner';
import WhiteLabel from '@/components/shared/WhiteLabel';
import VueRecaptcha from 'vue-recaptcha';
import FingerPrintApi from '@/services/FingerPrintApi';

export default {
  name: 'Login',
  async beforeMount() {
    const api = new LayoutApi();
    const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
    const subdomain = window.location.origin.match(subdomainRegex);

    if (!subdomain || subdomain[1] === 'gestao') {
      this.subdomain = null;
      this.resetLayout();

      this.readyToRender = true;
      return;
    }

    this.subdomain = subdomain ? subdomain[1] : null;
    api
      .fetch(subdomain[1])
      .then((response) => {
        this.layout = response;

        window.localStorage.setItem('primaryColor', response.primaryColor);
        window.localStorage.setItem('secondaryColor', response.secondaryColor);
        window.localStorage.setItem('tertiaryColor', response.tertiaryColor);
        window.localStorage.setItem('applicationId', response.applicationId);

        if (response.applicationName) {
          this.setApplicationName(response.applicationName);
          document.title = `${response.applicationName} - NetBanking`;
        } else {
          document.title = 'Pagcerto - NetBanking';
        }

        let link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement('link');
        link.type = 'image/png';
        link.rel = 'icon';
        link.href = response.logo;
        document.getElementsByTagName('head')[0].appendChild(link);

        this.readyToRender = true;
      })
      .catch(() => {
        this.readyToRender = true;
      });
  },
  async mounted() {
    // if (process.env.NODE_ENV === 'production') {
    //   $('#md-chat-widget').remove()
    //   window.name = ''
    //   window.email = ''
    //   window.phoneNumber = ''
    //   window.logged = false
    //   window.mdChatClient = `${process.env.VUE_APP_MOVIDESK_KEY}`
    //   let movideskScript = document.createElement('script')
    //   movideskScript.setAttribute('src', `${process.env.VUE_APP_MOVIDESK_URL}`)
    //   document.head.appendChild(movideskScript)
    // }
  },
  data() {
    return {
      title: 'Sign in to Business',
      username: null,
      password: null,
      subdomain: null,
      logoUrl: `${process.env.cdn}/img/logos/logo-white.png`,
      layout: {
        primaryColor: null,
        secondaryColor: null,
        tertiaryColor: null,
        logo: null,
        negativeLogo: null,
      },
      readyToRender: false,
      recaptcha: {
        siteKey: '6LdO8AgUAAAAAFhJc78nFBR5S-Q9h1vQDZ3D2wZx',
        token: null,
      },
    };
  },
  methods: {
    onVerify(token) {
      this.recaptcha.token = token;
    },
    onExpired() {
      this.recaptcha.token = null;
    },
    async signin() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      if (!this.recaptcha.token) {
        this.$v.recaptcha.$touch();
        return;
      }

      var alert = new Alert();

      if (/^\d+$/.test(this.username)) {
        const api = new AccountApi();
        this.$bus.$emit('spinner-run');
        const userCode = this.username;
        const response = await api.recoverEmail(userCode, this.password);
        if (response.status === 200) {
          this.$bus.$emit('spinner-stop');
          this.username = response.data.email;
          const name = response.data.fullName.split(' ');
          this.password = '';
          alert.title = `${name[0]}, a forma de acessar seu painel mudou!`;
          alert.message = `
            <div class="text-left">
              <p>A partir de agora ao invés do código de usuário, <b>você deve usar o seu e-mail para fazer login.</b></p>
              <p>Seu email cadastrado é: <b>${this.username}</b></p>
              <p>Digite sua senha para acessar o sistema.</p>
            </div>
          `;
          alert.confirmButtonText = 'Voltar a tela de login';
          this.$bus.$emit('alert-warning-html', alert);
          this.$refs.password.focus();
        } else if (response.status === 401) {
          this.$bus.$emit('spinner-stop');
          alert.title = 'Ops';
          alert.error = 'Código de usuário e/ou senha estão inválidos.';
          alert.confirmButtonText = 'OK';
          this.$bus.$emit('alert-error', alert);
        }
      } else {
        const api = new AccountApi();
        this.$bus.$emit('spinner-run');
        const response = await api.signin(
          this.username,
          this.password,
          this.subdomain
        );
        if (response.status === 401) {
          this.$bus.$emit('spinner-stop');
          alert.title = 'Ops';
          alert.error = 'E-mail e/ou senha estão inválidos.';
          alert.confirmButtonText = 'OK';
          this.$bus.$emit('alert-error', alert);
        } else if (response.status === 422) {
          const modelError = new LoginError(response.data);
          const error = modelError.getMessage();
          alert.title = 'Ops';
          alert.error = error;
          alert.confirmButtonText = 'OK';
          this.$bus.$emit('spinner-stop');
          this.$bus.$emit('alert-error', alert);
        } else {
          this.$bus.$emit('spinner-stop');
          this.auth(response.data);
          await this.askWhoAmI();
          await this.setFingerPrint(this.whoAmI.holder.id);
          this.resetPreviousNavigationConfigs();
          this.$router.push('/');
        }
      }
    },
    async setFingerPrint(holderId) {
      const fingerPrintApi = new FingerPrintApi();
      const fingerPrint = await fingerPrintApi.getFingerPrint(holderId);
      const timestamp = new Date().getTime();
      window.localStorage.removeItem('fingerPrint');
      window.localStorage.setItem('fingerPrint', JSON.stringify({ fingerPrint, timestamp }));
    },
    resetPreviousNavigationConfigs() {
      window.localStorage.removeItem('dashboardSeller');
      window.localStorage.removeItem('navigation');
    },
    ...mapActions({
      auth: 'account/login',
      askWhoAmI: 'account/askWhoAmI',
      resetLayout: 'layout/resetLayout',
      exit: 'account/logout',
      setApplicationName: 'layout/setApplicationName',
    }),
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
    recaptcha: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    usernameIsInvalid() {
      return this.$v.username.$invalid && this.$v.username.$dirty;
    },
    passwordIsInvalid() {
      return this.$v.password.$invalid && this.$v.password.$dirty;
    },
  },
  components: {
    AppSpinner,
    AppAlert,
    WhiteLabel,
    VueRecaptcha,
  },
};
</script>
