function dateAndTime(value) {
    if (!value) return value;

    var date = new Date(value.toString());
    var dateString = date.toLocaleDateString();
    var timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${dateString} às ${timeString}`;
}

export { dateAndTime };
