import CustomApiError from './CustomError';

export default class SendEmailRecoveryError extends CustomApiError {
  toDictionary() {
    return {
      USER_NOT_FOUND:
        'E-mail informado não está cadastrado na nossa base de dados.',
      INVALID_HASH: 'E-mail informado não é válido.',
      NOTIFICATION_FAILED:
        'Tivemos um problema ao tentar te enviar um e-mail, por favor, entre em contato com o nosso suporte.',
    };
  }
}
