import Vue from 'vue';
import VueMoment from 'vue-moment';
import App from '@/App';
import axios from 'axios';
import Alert from '@/plugins/alert';
import EventBus from '@/plugins/eventBus';
import router from '@/router';
import store from '@/store';
import Vuelidate from 'vuelidate';
import {
  UNAUTHORIZED_RESPONSE_INTERCEPTOR,
  REQUEST_TOKEN_INTERCEPTOR,
} from '@/interceptors';
import '@/filters/index';
import '@/directives/cpfCnpj';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';
import VueApexCharts from 'vue-apexcharts';
import Pagination from 'vue-pagination-2';
import { Chrome } from 'vue-color';

axios.defaults.timeout = 60000;

Vue.use(Alert);
Vue.use(EventBus);
Vue.use(Vuelidate);
Vue.use(Pagination);
Vue.use(VueMoment, {
  moment,
});
Vue.use(VCalendar, {
  firstDayOfWeek: 1,
  showDayPopover: false,

  themeStyles: {
    wrapper: {
      background: '#FFFFFF',
      color: '#7d8287',
    },
    header: {
      color: '#7d8287',
    },
    headerTitle: {
      fontWeight: '700',
    },
    weekdays: {
      padding: '10px',
      color: '#adb5bd',
    },
    dayCellNotInMonth: {
      opacity: '0.4',
    },
  },
});
Vue.component('Apexchart', VueApexCharts);
Vue.component('Pagination', Pagination);
Vue.component('ChromePicker', Chrome);

var infiniteScroll = require('vue-infinite-scroll');
Vue.use(infiniteScroll);

Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.config.VUE_APP_DEVTOOLS = process.env.VUE_APP_DEVTOOLS;

if (process.env.VUE_APP_DEVTOOLS === 'false') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
  Vue.config.productionTip = true;
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
  beforeCreate() {
    // add interceptors
    axios.interceptors.request.use(...REQUEST_TOKEN_INTERCEPTOR);
    axios.interceptors.response.use(...UNAUTHORIZED_RESPONSE_INTERCEPTOR);

    this.$store.dispatch('account/init');
    this.$store.dispatch('paymentFilters/init');
  },
});
