<template>
  <div>
    <app-alert />
    <app-spinner />
    <div class="container login-container">
      <WhiteLabel :theme="layout" v-if="readyToRender">
        <form @submit.prevent="createPassword" autocomplete="off">
          <div class="d-flex justify-content-around">
            <div class="login-form">
              <div class="pb-5">
                <WhiteLabel>
                  <img
                    class="login-img"
                    :src="layout.negativeLogo || layout.logo"
                  />
                </WhiteLabel>
                <img
                  v-if="!layout.logo && !layout.negativeLogo"
                  class="login-img"
                  src="https://cdn.pagcerto.com.br/img/logo/logo-branco.png"
                />
              </div>
              <div class="form-group pb-4">
                <div class="input-group">
                  <span class="input-group-addon login p-2 px-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Password"
                      data-name="Password"
                      viewBox="0 0 60 60"
                    >
                      <path
                        class="cls-1"
                        d="M48.07,60H11.93A4.92,4.92,0,0,1,7,55.09V24.28a4.92,4.92,0,0,1,4.93-4.91H48.07A4.92,4.92,0,0,1,53,24.28V55.09A4.92,4.92,0,0,1,48.07,60ZM11.93,21.22a3.07,3.07,0,0,0-3.07,3.06V55.09a3.06,3.06,0,0,0,3.07,3H48.07a3.06,3.06,0,0,0,3.07-3V24.28a3.07,3.07,0,0,0-3.07-3.06Z"
                      />
                      <path
                        class="cls-1"
                        d="M44,20.79a.93.93,0,0,1-.94-.93V14.51a12.55,12.55,0,0,0-25.09,0v5.35a.93.93,0,0,1-1.86,0V14.51a14.41,14.41,0,0,1,28.82,0v5.35A.93.93,0,0,1,44,20.79Z"
                      />
                      <path
                        class="cls-1"
                        d="M30,41.54a4.7,4.7,0,1,1,4.73-4.7A4.72,4.72,0,0,1,30,41.54Zm0-7.42a2.72,2.72,0,1,0,2.73,2.72A2.73,2.73,0,0,0,30,34.12Z"
                      />
                      <rect
                        class="cls-1"
                        x="28.73"
                        y="39.55"
                        width="2.53"
                        height="9.26"
                      />
                    </svg>
                  </span>
                  <input
                    type="password"
                    class="form-control input-login"
                    autofocus
                    v-model="password"
                    @input="$v.password.$touch()"
                    placeholder="Senha"
                  />
                </div>
                <small class="text-danger" v-if="passwordIsInvalid">
                  Senha obrigatória.
                </small>
                <small class="text-danger" v-if="!passwordHasLessThan8">
                  É necessário digitar no mínimo 8 caracteres .</small
                >
                <small class="text-danger" v-if="!passwordHasMoreThan20">
                  Precisa ter no máximo 20 caracteres.
                </small>
                <small class="text-danger" v-if="!passwordHasMoreThan20">
                  Precisa ter no máximo 20 caracteres.
                </small>
                <small
                  class="text-danger"
                  v-if="passwordHasLessThan8 && !$v.password.password"
                >
                  Senha inválida, Exemplo: Ex4plo@123.
                </small>
              </div>
              <div class="form-group pb-4">
                <div class="input-group">
                  <span class="input-group-addon login p-2 px-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Password"
                      data-name="Password"
                      viewBox="0 0 60 60"
                    >
                      <path
                        class="cls-1"
                        d="M48.07,60H11.93A4.92,4.92,0,0,1,7,55.09V24.28a4.92,4.92,0,0,1,4.93-4.91H48.07A4.92,4.92,0,0,1,53,24.28V55.09A4.92,4.92,0,0,1,48.07,60ZM11.93,21.22a3.07,3.07,0,0,0-3.07,3.06V55.09a3.06,3.06,0,0,0,3.07,3H48.07a3.06,3.06,0,0,0,3.07-3V24.28a3.07,3.07,0,0,0-3.07-3.06Z"
                      />
                      <path
                        class="cls-1"
                        d="M44,20.79a.93.93,0,0,1-.94-.93V14.51a12.55,12.55,0,0,0-25.09,0v5.35a.93.93,0,0,1-1.86,0V14.51a14.41,14.41,0,0,1,28.82,0v5.35A.93.93,0,0,1,44,20.79Z"
                      />
                      <path
                        class="cls-1"
                        d="M30,41.54a4.7,4.7,0,1,1,4.73-4.7A4.72,4.72,0,0,1,30,41.54Zm0-7.42a2.72,2.72,0,1,0,2.73,2.72A2.73,2.73,0,0,0,30,34.12Z"
                      />
                      <rect
                        class="cls-1"
                        x="28.73"
                        y="39.55"
                        width="2.53"
                        height="9.26"
                      />
                    </svg>
                  </span>
                  <input
                    type="password"
                    class="form-control input-login"
                    v-model="confirmPassword"
                    @input="$v.confirmPassword.$touch()"
                    placeholder="Confirme sua senha"
                  />
                </div>
                <small class="text-danger" v-if="!passwordConfirmed"
                  >* Confirmação de senha obrigatória.</small
                >
              </div>
              <div class="form-group pb-5">
                <button
                  type="submit"
                  class="btn btn-block btn-grass text-uppercase"
                  :disabled="$v.$invalid || !passwordConfirmed"
                >
                  Cadastrar nova senha
                </button>
              </div>
              <div
                class="row text-white font-weight-light justify-content-center"
              >
                <div class="col-auto">
                  <router-link
                    class="text-white font-weight-light text-10pt"
                    href="#"
                    :to="'/acessar'"
                    >Acessar minha conta</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </WhiteLabel>
    </div>
    <WhiteLabel v-if="readyToRender">
      <div class="footer">
        <small class="text-uppercase">
          <p class="text-center font-weight-light text-white pt-2">
            Copyright &#9400; 2020 Pagcerto. Todos os direitos reservados.
          </p>
        </small>
      </div>
    </WhiteLabel>
  </div>
</template>

<script>
import AccountApi from '@/services/AccountApi';
import Alert from '@/models/Alert';
import AppAlert from '@/components/theme/Alert';
import AppSpinner from '@/components/theme/Spinner';
import CreateNewPasswordError from '@/errors/CreateNewPasswordError';
import WhiteLabel from '@/components/shared/WhiteLabel';
import LayoutApi from '@/services/v2/LayoutApi';
import { mapActions, mapGetters } from 'vuex';

// validation imports
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import { password } from '@/validations/password';

export default {
  name: 'CreateNewPassword',
  async beforeMount() {
    const api = new LayoutApi();
    const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
    const subdomain = window.location.origin.match(subdomainRegex);

    if (subdomain && subdomain[1] === 'gestao') {
      this.createPage();

      this.readyToRender = true;
      return;
    }

    this.subdomain = subdomain ? subdomain[1] : null;
    api.fetch(subdomain[1]).then((response) => {
      this.layout = response;

      window.localStorage.setItem('primaryColor', response.primaryColor);
      window.localStorage.setItem('secondaryColor', response.secondaryColor);
      window.localStorage.setItem('tertiaryColor', response.tertiaryColor);
      window.localStorage.setItem('applicationId', response.applicationId);

      if (response.applicationName) {
        this.setApplicationName(response.applicationName);
        document.title = `${response.applicationName} - NetBanking`;
      } else {
        document.title = 'Pagcerto - NetBanking';
      }

      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link');
      link.type = 'image/png';
      link.rel = 'icon';
      link.href = response.logo;
      document.getElementsByTagName('head')[0].appendChild(link);

      this.createPage();
      this.readyToRender = true;
    });
  },
  data() {
    return {
      title: 'Create new password',
      password: null,
      confirmPassword: null,
      logoUrl: `${process.env.cdn}/img/logos/logo-white.png`,
      token: null,
      subdomain: null,
      readyToRender: false,
      layout: {
        primaryColor: null,
        secondaryColor: null,
        tertiaryColor: null,
        logo: null,
        negativeLogo: null,
      },
    };
  },
  methods: {
    async createPassword() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      const api = new AccountApi();
      this.$bus.$emit('spinner-run');
      const response = await api.createPassword(this.password, this.token);
      if (response.error === 401) {
        this.$bus.$emit('spinner-stop');
        var alert = new Alert();
        alert.title = 'Ops';
        alert.error = 'Você não tem autorização para isso.';
        alert.confirmButtonText = 'Sair';
        this.$bus.$emit('alert-error', alert);
        return;
      }
      const title = 'Pronto!';
      this.$alert.onClose = async() => this.$router.push('/');
      this.$alert.info(title, 'Senha cadastrada com sucesso.');
      this.$bus.$emit('spinner-stop');
    },
    async createPage() {
      const api = new AccountApi();
      var applicationId =
        window.localStorage.getItem('applicationId') != null
          ? window.localStorage.getItem('applicationId')
          : process.env.VUE_APP_APPLICATION_ID;

      await api
        .autoSignIn(this.$route.params.hash, applicationId)
        .then((response) => {
          if (response.status === 422) {
            const title = 'Ops';
            const modelError = new CreateNewPasswordError(response.data);
            const error = modelError.getMessage();
            this.$alert.error(title, `${error}`);
            this.$router.push('/');
          }
          this.token = response.data.token;
        });
    },
    ...mapActions({
      fetchLayout: 'layout/fetchLayout',
      setApplicationName: 'layout/setApplicationName',
    }),
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(20),
      password,
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
  computed: {
    passwordIsInvalid() {
      return this.password === '' && this.$v.password.$dirty;
    },
    passwordHasLessThan8() {
      return this.$v.password.minLength;
    },
    passwordHasMoreThan20() {
      return this.$v.password.maxLength;
    },
    passwordConfirmed() {
      return this.password === this.confirmPassword;
    },
  },
  components: {
    AppSpinner,
    AppAlert,
    WhiteLabel,
  },
};
</script>
