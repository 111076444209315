import account from '@/store/account/index.js';
import creditProtection from '@/store/credit-protection/index.js';
import paymentFilters from '@/store/payment-filters/index.js';
import layout from '@/store/layout/index';
import blockedFunctionalities from '@/store/blockedFunctionalities/index';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    account,
    creditProtection,
    paymentFilters,
    layout,
    blockedFunctionalities,
  },
});

store.subscribe((mutation, state) => {
  if (mutation.type === 'account/APP_INIT') return;
  localStorage.setItem('state', JSON.stringify(state));
});

export default store;
