import AccountApi from '@/services/AccountApi';

import { SET_BLOCKED_FUNCTIONALITIES_LIST } from './mutations-type.js';

const moduleBlockedFunctionalities = {
  namespaced: true,
  state: {
    blockedFunctionalityList: [],
    loaded: false,
  },
  actions: {
    async initialize(context) {
      if (!context.getters['isLoaded']) {
        var list = [];

        const api = new AccountApi();

        var result = await api.features.getList();
        if (result.status === 200)
          list = result.data.listFeatures.map((x) => x.featureName);

        context.commit(SET_BLOCKED_FUNCTIONALITIES_LIST, list);
      }
    },
  },
  mutations: {
    [SET_BLOCKED_FUNCTIONALITIES_LIST](state, list) {
      state.blockedFunctionalityList = list;
      state.loaded = true;
    },
  },
  getters: {
    isLoaded: (state) => {
      return state.loaded;
    },
    isBlocked: (state) => (functionality) => {
      return state.blockedFunctionalityList.includes(functionality);
    },
    anyIsBlocked: (state) => (functionalities) => {
      if (functionalities && functionalities.length) {
        return state.blockedFunctionalityList.some((x) =>
          functionalities.includes(x)
        );
      }

      return false;
    },
    listFunctionalities(state) {
      return state.blockedFunctionalityList;
    },
  },
};

export default moduleBlockedFunctionalities;
