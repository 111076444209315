export const SHOWINPUTNAME = 'SHOWINPUTNAME';
export const SHOWINPUTCPF = 'SHOWINPUTCPF';
export const BANKSLIP = 'BANKSLIP';
export const CREDIT = 'CREDIT';
export const DEBIT = 'DEBIT';
export const PAID = 'PAID';
export const DELAYED = 'DELAYED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const PENDING = 'PENDING';
export const CANCELED = 'CANCELED';
export const CONTESTED = 'CONTESTED';
export const LAST7DAYS = 'LAST7DAYS';
export const LAST30DAYS = 'LAST30DAYS';
export const STARTDATE = 'STARTDATE';
export const ENDDATE = 'ENDDATE';
export const CARDBRAND = 'CARDBRAND';
export const SEARCH = 'SEARCH';
export const PAYERTAXDOCUMENT = 'PAYERTAXDOCUMENT';
export const PAYERNAMEINDEX = 'PAYERNAMEINDEX';
export const PAYERNAME = 'PAYERNAME';
export const BANSKSLIPIDINDEX = 'BANSKSLIPIDINDEX';
export const BANKSLIPID = 'BANKSLIPID';
export const NSUINDEX = 'NSUINDEX';
export const NSU = 'NSU';
export const CARDFINAL = 'CARDFINAL';
export const NOTEINDEX = 'NOTEINDEX';
export const NOTE = 'NOTE';
export const SEARCHTYPE = 'SEARCHTYPE';
export const INIT = 'INIT';
