import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

export default class FingerPrintApi {
  async getFingerPrint(holderId) {
    const fpPromise = FingerprintJS.load({ apiKey: `${process.env.FINGER_PRINT_API_KEY}` });

    let fingerPrint = null;

    await fpPromise.then(fp => {
      return fp.get({ linkedId: holderId });
    }).then(result => {
      fingerPrint = result;
    });

    return fingerPrint;
  }

  async verifyFingerPrintExpiration(holderId) {
      const storedData = JSON.parse(localStorage.getItem('fingerPrint'));

      if (storedData) {
        const { fingerPrint, timestamp } = storedData;
        const currentTime = new Date().getTime();

        if (currentTime - timestamp > 3600000) {
          const newFingerPrint = await this.getFingerPrint(holderId);
          const newTimestamp = new Date().getTime();

          localStorage.setItem('fingerPrint', JSON.stringify({ fingerPrint: newFingerPrint, timestamp: newTimestamp }));
        }
      } else {
        const newFingerPrint = await this.getFingerPrint(holderId);
        const newTimestamp = new Date().getTime();
        localStorage.setItem('fingerPrint', JSON.stringify({ fingerPrint: newFingerPrint, timestamp: newTimestamp }));
      }
    }
}
