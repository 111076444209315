import Router from 'vue-router';
import store from '@/store';
import RemoteAccess from '@/components/account/RemoteAccess';
import ChargebackRemoteAccess from '@/components/account/ChargebackRemoteAccess';
import SendEmailRecovery from '@/components/account/SendEmailRecovery';
import UploadDocuments from '@/components/activation/UploadDocuments';
import ConfirmationSms from '@/components/activation/ConfirmationSms';
import LinkExpired from '@/components/activation/LinkExpired';
import CreateNewPassword from '@/components/account/CreateNewPassword';
import Login from '@/components/account/Login';
import Vue from 'vue';
import { appRoutes } from './module.router.js';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/acessar',
      name: 'Signin',
      component: Login,
      meta: { allowAnonymousAccess: true },
    },
    {
      path: '/esqueci-minha-senha',
      name: 'SendEmailRecovery',
      component: SendEmailRecovery,
      meta: {
        allowAnonymousAccess: true,
        title: 'Pagcerto - NetBanking - Esqueci minha senha',
      },
    },
    {
      path: '/criar-nova-senha/:hash',
      name: 'CreateNewPassword',
      component: CreateNewPassword,
      meta: {
        allowAnonymousAccess: true,
        title: 'Pagcerto - NetBanking - Criar nova senha',
      },
    },
    {
      path: '/acesso-remoto',
      name: 'remote-access',
      component: RemoteAccess,
      meta: { allowAnonymousAccess: true },
    },
    {
      path: '/acesso-remoto-chargeback',
      name: 'chargeback-remote-access',
      component: ChargebackRemoteAccess,
      meta: { allowAnonymousAccess: true },
    },
    {
      path: '/ativacao',
      name: 'activation',
      component: UploadDocuments,
      children: [
        {
          path: '/confirmacao-sms',
          name: 'ConfirmationSms',
          component: ConfirmationSms,
        },
      ],
      meta: {
        allowAnonymousAccess: true,
        title: 'Pagcerto - NetBanking - Ativação',
      },
    },
    {
      path: '/confirmacao-sms',
      name: 'confirmation-sms',
      component: ConfirmationSms,
      meta: { allowAnonymousAccess: true },
    },
    {
      path: '/link-expirou',
      name: 'link-expired',
      component: LinkExpired,
      meta: { allowAnonymousAccess: true },
    },
    appRoutes,
  ],
});

async function CheckBlockedFunctionalities(to, next) {
  await store.dispatch('blockedFunctionalities/initialize');

  var routeBlocked = false;
  if (to.meta.functionalityTags && to.meta.functionalityTags.length) {
    routeBlocked = store.getters['blockedFunctionalities/anyIsBlocked'](
      to.meta.functionalityTags
    );
  }

  if (routeBlocked) {
    return next({ path: '/' });
  } else return next();
}

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

router.beforeResolve((to, from, next) => {
  store.dispatch('account/Initialize').then(() => {
    const auth = store.getters['account/isAuthenticated'];
    if (!auth) {
      if (to.matched.some((record) => record.meta.allowAnonymousAccess)) {
        next();
      } else {
        next({ path: '/acessar' });
      }
    } else if (to.path === '/acessar') {
      next({ path: '/' });
    } else {
      if (
        store.getters['creditProtection/creditProtectionIsLeavingTheScreen']
      ) {
        var result = confirm('Deseja realmente sair da tela do SPC/SERASA?');
        if (!result) {
          return;
        }
        store.dispatch('creditProtection/ExitQueryScreenSpcSerasa');
      }
      return CheckBlockedFunctionalities(to, next);
    }
  });
});
/* eslint-disable no-undef */
ga('set', 'page', router.currentRoute.path);
ga('send', 'pageview');

router.afterEach((to, from) => {
  // Destroy ModalBase opacity element if the page change
  let elements = document.getElementsByClassName('modal-backdrop');
  for(let i=0; i< elements.length; i++)
    elements[i].remove();
  // End Destroy ModalBase
  ga('set', 'page', to.path);
  ga('send', 'pageview');
});

export default router;
